import { useEffect, useState, FC } from "react";
import { Row, Button, Col, Table, Input, Select, DatePicker, message } from "antd";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Header } from "../components/Header";
import icGoBack from "../../img/ic-goback.svg";
import icSave from "../../img/ic-save.svg";
import icPlusGrey from "../../img/ic-plus-grey.svg";
import { GroupStatus, StudyGroup } from "./StudyGroupsLayout";
import { Language } from "../../App";
import { http } from "../../helpers/http";
import { AxiosError, AxiosResponse } from 'axios';
import { AddStudentsToGroupModal } from "./AddStudentsToGroupModal";
import { Student } from "../Students/StudentsLayout";
import { ColumnsType } from 'antd/es/table';
import { EndFormingGroupModal } from "./EndFormingGroupModal";
import dayjs from "dayjs";
import moment from "moment";
import { useTranslation } from 'react-i18next';

type StudyGroupOpeningProps = {
    title: string;
    studyGroups: StudyGroup[];
    languages: Language[];
    groupStatuses: GroupStatus[];
    refreshStudyGroups: () => void;
}

type EditGroupData = {
    csdd_nr?: string | null;
    group_status?: number | null;
    start_date?: string | null;
    planned_slots?: number | null;
}

export const StudyGroupOpening = ({ title, studyGroups, languages, groupStatuses, refreshStudyGroups }: StudyGroupOpeningProps) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [studyGroup, setStudyGroup] = useState<StudyGroup | null>(null);
    const [addStudentsModalOpen, setAddStudentsModalOpen] = useState(false);
    const [students, setStudents] = useState<Student[] | []>([]);
    const [endGroupFormingModalOpen, setEndFormingModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editGroupData, setEditGroupData] = useState<EditGroupData>({})

    const getStudents = () => {
        http.get(`group-students/${id}`).then((res: AxiosResponse) => {
            console.log(res)
            const { students } = res.data.data;
            setStudents(
                students.map((student: any): Student => {
                    return {
                        first_name: student.first_name,
                        last_name: student.last_name,
                        email: student.email,
                        phone_number: student.phone_number,
                        personal_identity_number: student.personal_identity_number,
                        id: student.id,
                        reservation_type: student.pivot.reservation_type,
                        elearning_progress: {
                            max_lessons: student.category.e_learning_lesson_counter,
                            done_lessons: student.module_counter
                        }
                    }
                })
            )
        })
    };

    const updateStudyGroup = () => {
        http.post(`change-group/${id}`, { ...editGroupData }).then((res: AxiosResponse) => {
            message.success(t("notification.group_changes_saved"));
            getStudyGroup();
            // refreshStudyGroups();
            setEditMode(false);
        }).catch((err: AxiosError) => {
            message.error(t("errors.error"));
        })
    };

    const getStudyGroup = () => {
        http.get(`study-group/${id}`).then((res: AxiosResponse) => {
            const { data: group } = res.data;
            setStudyGroup(() => {
                return {
                    id: group[0].id,
                    category: {
                        id: group[0].category.id,
                        value: group[0].category.value
                    },
                    csdd_nr: group[0].csdd_nr,
                    group_status: group[0].group_status,
                    start_date: moment(group[0].start_date, "YYYY-MM-DD").format("DD.MM.YYYY"),
                    languages: group[0].languages,
                    planned_slots: group[0].planned_slots,
                    free_slots: group[0].free_slots
                } as StudyGroup;
            })
        })
    }

    const columns: ColumnsType<Student> = [
        {
            title: t("settings.name"),
            dataIndex: "first_name",
            key: "first_name",
            fixed: "left"
        },
        {
            title: t("settings.surname"),
            dataIndex: "last_name",
            key: "last_name"
        },
        {
            title: t("common.personal_id"),
            dataIndex: "personal_identity_number",
            key: "personal_identity_number"
        },
        // {
        //     title: "Status",
        //     dataIndex: "reservation_type",
        //     key: "reservation_type"
        // },
        {
            title: t("common.progress"),
            render: (nothing: any, student: Student): React.ReactNode => {
                return student.elearning_progress?.done_lessons + "/" + student.elearning_progress?.max_lessons
            }
        },
        {
            title: t("settings.phone"),
            dataIndex: "phone_number",
            key: "phone"
        },
        {
            title: t("settings.email"),
            dataIndex: "email",
            key: "email"
        },
        {
            render: (nothing: any, record: Student): React.ReactNode => {
                return <Button
                    onClick={() => navigate(`/students/${record.id}`)}
                >
                    {t("common.open")}
                </Button>
            }
        }
    ]

    useEffect(() => {
        getStudyGroup();
    }, [])

    // useEffect(() => {
    //     if (studyGroups.length > 0) {
    //         setStudyGroup(studyGroups.find((group: StudyGroup) => group.id === Number(id)) || null);

    //     }
    // }, [studyGroups]);

    useEffect(() => {
        setEditGroupData({
            csdd_nr: studyGroup?.csdd_nr,
            group_status: studyGroup?.group_status?.id === undefined ? null : studyGroup?.group_status?.id,
            start_date: dayjs(studyGroup?.start_date, "DD.MM.YYYY").format("YYYY-MM-DD"),
            planned_slots: studyGroup?.planned_slots
        })
    }, [studyGroup]);

    useEffect(() => {
        getStudents();
        return () => refreshStudyGroups();
    }, []);

    return <>
        <AddStudentsToGroupModal
            group={studyGroup}
            languages={languages}
            open={addStudentsModalOpen}
            setOpen={(state) => setAddStudentsModalOpen(state)}
            refreshStudents={getStudents}
            refreshStudyGroups={getStudyGroup}
        />
        <EndFormingGroupModal
            groupID={studyGroup?.id ?? 0}
            open={endGroupFormingModalOpen}
            setOpen={setEndFormingModalOpen}
            refreshGroup={getStudyGroup}
        />
        <Header
            title={title}
        />
        <div style={{ padding: 20 }}>
            <div className="shadow-card" style={{ padding: 30 }}>
                <Row justify="space-evenly" gutter={[30, 10]} style={{ paddingBottom: 20 }}>
                    <Col>
                        <Link
                            to="/study-groups"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: 38,
                                height: 38,
                                borderRadius: 4,
                                backgroundColor: "#F0F2F5"
                            }}
                        >
                            <img src={icGoBack} />
                        </Link>
                    </Col>
                    <Col>
                        <div>
                            <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("groups.csdd_group")}:</p>
                            {
                                editMode
                                    ? <Input
                                        style={{ width: 120 }}
                                        value={editGroupData.csdd_nr ?? ""}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setEditGroupData((prev: EditGroupData): EditGroupData => {
                                                return {
                                                    ...prev,
                                                    csdd_nr: e.target.value.length > 0
                                                        ? e.target.value
                                                        : null
                                                }
                                            })
                                        }}
                                    />
                                    : <b style={{ fontSize: 14, lineHeight: "22px" }}>{studyGroup?.csdd_nr}</b>
                            }
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("common.category")}:</p>
                            <b style={{ fontSize: 14, lineHeight: "22px" }}>{studyGroup?.category.value}</b>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("common.language")}:</p>
                            <b style={{ fontSize: 14, lineHeight: "22px" }}>
                                {
                                    studyGroup?.languages !== undefined
                                        ? studyGroup.languages.map((language: Language, index: number): React.ReactNode => {
                                            return language.iso + ""
                                        })
                                        : ""
                                }
                            </b>
                        </div>
                    </Col>
                    {/* <Col>
                        <div>
                            <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("common.status")}:</p>
                            {
                                editMode
                                    ? <Select
                                        style={{ width: 120 }}
                                        value={editGroupData.group_status}
                                        onChange={(e: number) => {
                                            setEditGroupData((prev: EditGroupData): EditGroupData => {
                                                return {
                                                    ...prev,
                                                    group_status: e > 0 ? e : null
                                                }
                                            })
                                        }}
                                    >
                                        {editGroupData.group_status && <Select.Option value={-1}> </Select.Option>}
                                        {
                                            groupStatuses?.map((status: GroupStatus): React.ReactNode => {
                                                return <Select.Option value={status.id}>
                                                    {status.value}
                                                </Select.Option>
                                            })
                                        }
                                    </Select>
                                    : <b style={{ fontSize: 14, lineHeight: "22px" }}>
                                        {
                                            studyGroup?.group_status
                                                ? studyGroup?.group_status.value
                                                : ""
                                        }
                                    </b>
                            }
                        </div>
                    </Col> */}
                    <Col>
                        <div>
                            <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("common.start")}:</p>
                            {
                                editMode
                                    ? <DatePicker
                                        format="DD.MM.YYYY"
                                        value={editGroupData.start_date ? dayjs(editGroupData.start_date, "YYYY-MM-DD") : undefined}
                                        onChange={(e, date: string) => {
                                            setEditGroupData((prev: EditGroupData): EditGroupData => {
                                                return {
                                                    ...prev,
                                                    start_date: date ? dayjs(date, "DD.MM.YYYY").format("YYYY-MM-DD") : null
                                                }
                                            })
                                        }}
                                    />
                                    : <b style={{ fontSize: 14, lineHeight: "22px" }}>{studyGroup?.start_date}</b>
                            }
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("groups.max_students")}:</p>
                            {
                                editMode
                                    ? <Input
                                        style={{ width: 120 }}
                                        type="number"
                                        min={0}
                                        step={1}
                                        value={editGroupData.planned_slots ?? ""}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setEditGroupData((prev: EditGroupData): EditGroupData => {
                                                return {
                                                    ...prev,
                                                    planned_slots: e.target.value.length > 0
                                                        ? Number(e.target.value)
                                                        : null
                                                }
                                            })
                                        }}
                                    />
                                    : <b style={{ fontSize: 14, lineHeight: "22px" }}>{studyGroup?.planned_slots}</b>
                            }
                        </div>
                    </Col>
                    <Col style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                        <Row>
                            <Button
                                style={{ marginRight: 10 }}
                                onClick={() => setEditMode(!editMode)}
                            >
                                {
                                    editMode
                                        ? t("common.cancel")
                                        : t("common.edit")
                                }
                            </Button>
                            {
                                editMode
                                && <Button
                                    onClick={updateStudyGroup}
                                    disabled={
                                        (
                                            editGroupData.csdd_nr === null
                                            || editGroupData.group_status === null
                                            || editGroupData.start_date === null
                                            || editGroupData.planned_slots === null
                                        ) ||
                                        (
                                            editGroupData.csdd_nr === studyGroup?.csdd_nr
                                            && editGroupData.group_status === studyGroup?.group_status?.id
                                            && editGroupData.start_date === dayjs(studyGroup?.start_date, "DD.MM.YYYY").format("YYYY-MM-DD")
                                            && editGroupData.planned_slots === studyGroup?.planned_slots
                                        )
                                    }
                                >
                                    {t("settings.save_changes")}
                                </Button>
                            }
                        </Row>
                    </Col>
                    {
                        studyGroup?.group_status?.id !== 3
                        && <Col style={{ margin: "0 0 0 auto" }}>
                            <Button
                                type="primary"
                                style={{ display: "flex", alignItems: "center", height: 38 }}
                                onClick={() => setEndFormingModalOpen(true)}
                            >
                                <img src={icSave} style={{ marginRight: 8 }} />
                                {t("groups.end_complecting")}
                            </Button>
                        </Col>
                    }
                </Row>
                <div
                    style={{
                        borderTop: "1px solid #E4E7EB",
                        paddingTop: 20
                    }}
                >
                    <Row justify="space-between" style={{ marginBottom: 20 }}>
                        <Col>
                            <div>
                                <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("common.students")}:</p>
                                <b style={{ fontSize: 14, lineHeight: "22px" }}>{(studyGroup?.planned_slots ?? 0) - (studyGroup?.free_slots ?? 0)}/{studyGroup?.planned_slots}</b>
                            </div>
                        </Col>
                        <Col>
                            <Button
                                // type="primary"
                                style={{ display: "flex", alignItems: "center", height: 38 }}
                                onClick={() => setAddStudentsModalOpen(true)}
                            >
                                <img src={icPlusGrey} style={{ marginRight: 8 }} />
                                {t("groups.add_student")}
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        rowKey={"id"}
                        columns={columns}
                        dataSource={students}
                        pagination={false}
                    />
                </div>
            </div>
        </div>

    </>
}