import React, { useEffect, useState } from "react";
import { Language } from "../../App";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";

export const LanguagesLayout = () => {
    const { catid, mid, cid } = useParams();
    const navigate = useNavigate();
    const [languages, setLanguages] = useState<Language[]>([]);

    const getLanguages = () => {
        http.get(`languages`).then((res: AxiosResponse) => {
            setLanguages(res.data.data);
        });
    };

    useEffect(() => {
        getLanguages();
    }, []);

    return <div style={{ padding: 20 }}>
        <div className="shadow-card" style={{ padding: 30 }}>
            {
                languages.map((language: Language) => {
                    if (language.iso === 'en') return null;
                    return <button
                        style={{ marginBottom: 5 }}
                        key={language.id}
                        className="shadow-card big-category-btn"
                        onClick={() => navigate(`/modules/${catid}/${mid}/${cid}/${language.id}/${language.iso}`)}
                    >
                        {language.iso?.toUpperCase()}
                    </button>
                })
            }
        </div>
    </div>
}