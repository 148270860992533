import { Button, Col, message, Modal, Row } from "antd";
import icWarning from "../../img/ic-warning.svg";
import icSave from "../../img/ic-save.svg";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

type EndFormingGroupModalProps = {
    open: boolean;
    setOpen: (state: boolean) => void;
    groupID: number;
    refreshGroup: () => void;
}

export const EndFormingGroupModal = ({ open, setOpen, groupID, refreshGroup }: EndFormingGroupModalProps) => {
    const { t } = useTranslation();
    const approveGroup = () => {
        http.post(`approve-group-students-crendentials/${groupID}`).then((res: AxiosResponse) => {
            message.success(t("notification.group_confirmed"));
            refreshGroup();
            setOpen(false);

        }).catch(() => {
            message.error(t("errors.error"))
            setOpen(false)
        })
    }

    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        bodyStyle={{ padding: "10px 6px" }}
        width={320}
        footer={<Row gutter={[10, 10]}>
            <Col span={24}>
                <Button
                    style={{ width: "100%", height: 38, display: "flex", alignItems: "center", justifyContent: "center" }}
                    type="primary"
                    onClick={approveGroup}
                >
                    <img src={icSave} style={{ marginRight: 8 }} />
                    {t("common.finish_and_send")}
                </Button>
            </Col>
            <Col span={24}>
                <Button
                    style={{ width: "100%", height: 38 }}
                    onClick={() => setOpen(false)}
                >
                    {t("common.cancel_action")}
                </Button>
            </Col>
        </Row>}
    >
        <Row>
            <Col span={24} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div
                    style={{
                        marginBottom: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 60,
                        height: 60,
                        borderRadius: "50%",
                        backgroundColor: "rgba(8,131,239,0.1)"
                    }}
                >
                    <img src={icWarning} />
                </div>
                <span style={{ fontSize: 20, color: "#303136", textAlign: "center" }}>
                    {t("notification.you_sure_want_confirm_group")}
                </span>
                <p style={{ textAlign: "center", marginBottom: 0 }}>
                    {t("notification.action_after_confirm_group")}
                </p>
            </Col>
        </Row>
    </Modal >
}