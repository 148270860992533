import { Row, Col, Input, Button, message, Form } from "antd";
import { KeyboardEventHandler, useState } from "react";
import { Link } from "react-router-dom";
import { http } from "../../helpers/http";
import { LanguageSwitch } from "../components/LanguageSwitch";
import { useTranslation } from 'react-i18next';

type Credentials = {
    email: string,
    password: string
}

export const LoginForm = () => {
    const { t } = useTranslation();
    const [credentials, setCredentials] = useState<Credentials>({
        email: "",
        password: ""
    });

    const login = () => {
        http.post(`school-login`, { ...credentials }).then((res: any) => {
            if (!!!res.data.data.school.active) {
                message.error("Skola nav aktīva!")
                return;
            }
            if (res.data.data.api_token) {
                sessionStorage.setItem("bearer_token", res.data.data.api_token);
                window.location.href = "/"
            }
        }).catch((err: any) => {
            message.error("Nepareizs epasts vai parole")
        })
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            login();
        }
    }

    return <div className="login-card"
        onKeyDown={handleKeyPress}>
        <Row justify={"space-between"} style={{ marginBottom: 28 }}>
            <h2 style={{ margin: 0 }}>{t('login.authorization')}</h2>
            {/* <LanguageSwitch /> */}
        </Row>
        <Form>
            <Row>
                <Col span={24}>
                    <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("login.e_mail")}</p>
                    <Form.Item style={{ margin: 0 }} name="email">
                        <Input
                            style={{ marginBottom: 20 }}
                            type="email"
                            value={credentials.email}
                            onChange={(e) => {
                                setCredentials((prev: any) => {
                                    return {
                                        ...prev,
                                        email: e.target.value
                                    }
                                })
                            }}
                        />
                    </Form.Item>
                    <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("login.password")}</p>
                    <Input.Password
                        style={{ marginBottom: 20 }}
                        value={credentials.password}
                        onChange={(e) => {
                            setCredentials((prev: any) => {
                                return {
                                    ...prev,
                                    password: e.target.value
                                }
                            })
                        }}
                    />
                </Col>
            </Row>
        </Form>
        <Row justify={"space-between"} style={{ marginBottom: 30 }}>
            <div></div>
            <Button
                style={{ padding: 0, height: "unset" }}
                type="link"
            >
                <Link to="/renew-password">{t("login.forgot_password?")}</Link>
            </Button>
        </Row>
        <Button
            type="primary"
            style={{ width: "100%", height: 38 }}
            onClick={login}
            disabled={credentials.email.length === 0 || credentials.password.length === 0}
        >
            {t("login.sign_in")}
        </Button>
    </div>
}