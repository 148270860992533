
interface SummaryCardProps {
    title: string,
    icon: string,
    counter: number
}

export const SummaryCard = ({ title, icon, counter }: SummaryCardProps) => {
    return <div
        style={{
            boxShadow: "0 3px 6px 0 rgba(64,96,128,0.1)",
            backgroundColor: "#FFFFFF",
            borderRadius: 4,
            border: "1px solid #CED4D9",
            display: "flex",
            alignItems: "center",
            padding: 20
        }}
    >
        <div
            style={{
                width: 44,
                height: 44,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,153,51,0.1)",
                borderRadius: "50%"
            }}
        >
            <img src={icon} />
        </div>
        <div style={{ marginLeft: 14 }}>
            <p style={{ margin: 0, color: "#667380" }}>{title}</p>
            <span
                style={{
                    fontSize: 21,
                    color: "#303136",
                    textAlign: "center",
                    fontWeight: "bolder"
                }}
            >
                {counter}
            </span>
        </div>
    </div>
}