import { Button, Col, Input, message, Modal, Row } from "antd";
import { Link } from "react-router-dom";
import icSave from "../../img/ic-save-grey.svg";
import icEmail from "../../img/ic-email.svg";
import icNewTab from "../../img/ic-new-tab.svg";
import icSaved from "../../img/ic-save-small.svg";
import { Message } from "./MessagesLayout";
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import { http } from "../../helpers/http";
import { useTranslation } from 'react-i18next';
import { Language } from "../../App";

type MessageCardProps = {
    messageObj: Message;
    refreshMessages: () => void;
    language: Language | undefined;
}

export const MessageCard = ({ messageObj, refreshMessages, language }: MessageCardProps) => {
    const { t } = useTranslation();
    const [openAnswerQuestion, setOpenAnswerQuestion] = useState(false);
    const [answer, setAnswer] = useState("");

    const replyToAMessage = (skipSpam: boolean = false) => {
        Modal.confirm({
            title: skipSpam ? t("notification.mark_as_read") : t("e_learning.reply_to_student") + messageObj.student,
            content: skipSpam
                ? <></>
                : <div>
                    <p><b>{t("e_learning.questions")}: </b>{messageObj.question}</p>
                    <p><b>{t("e_learning.answer")}: </b>{answer}</p>
                </div>,
            onOk: () => {
                http.post(`answer-student-question/${messageObj.id}`,
                    {
                        answer,
                        skip_spam: skipSpam ? 1 : 0
                    }
                ).then(() => {
                    message.success(skipSpam ? t("common.accepted") : t("e_learning.answer_was_sent"))
                    refreshMessages();
                    setOpenAnswerQuestion(false);
                }).catch(() => {
                    message.error(t("errors.error"))
                })
            }
        })
    }

    return <div className="message-card">
        <Row justify={"space-between"}>
            <Col>
                <Row gutter={16}>
                    <Col>
                        <div className={`initials-bubble ${!!messageObj.answered ? "answered" : ""}`} >
                            {messageObj.student.split(" ").map((part: string) => part[0].toUpperCase()).join("")}
                        </div>
                    </Col>
                    <Col>
                        <Row style={{ marginBottom: 3 }}>
                            <b className="name" style={{ marginRight: 20 }}>{messageObj.student}</b>
                            <span className="info" style={{ marginRight: 20 }}>{messageObj.csdd_nr}</span>
                            <span className="info" style={{ marginRight: 20 }}>{messageObj.category} {t("common.category")}</span>
                            <span className="info" style={{ marginRight: 20 }}>{messageObj.created_at.split(" ")[0]}</span>
                            <span className="info" style={{ marginRight: 20 }}>{messageObj.created_at.split(" ")[1]}</span>
                        </Row>
                        <Row>
                            <span className="info small" style={{ marginRight: 10 }}>{messageObj.ind}. {t("theory.lesson")} {t("e_learning.theme")}:</span>
                            <span className="info small" style={{ marginRight: 10 }}>{messageObj[`chapter_title_${language ? language.iso : "lv"}` as keyof Message]}</span>
                            <span className="info small" style={{ marginRight: 10 }}>{t("e_learning.video_time")}: {dayjs(String(messageObj.seconds), "s").format("HH:mm:ss")}</span>
                            {
                                !!!messageObj.answered
                                && <Link className="link small" to={`/modules/${messageObj.category_id}/${messageObj.e_module_id}/${messageObj.e_chapter_id}/${language ? language.id : 1}/${language ? language.iso : "lv"}`} state={{ jumpTo: messageObj.seconds, message: messageObj }}>
                                    {t("common.open")}
                                    <img style={{ marginLeft: 4 }} src={icNewTab} />
                                </Link>
                            }
                        </Row>
                        <Row>
                            <p style={{ marginBottom: 4 }} className="question">J: "{messageObj.question}"</p>
                        </Row>
                        {
                            messageObj.answer !== "0" && messageObj.answer
                            && <Row>
                                <i style={{ marginBottom: 10 }}>A: {messageObj.answer}</i>
                            </Row>
                        }
                        <Row>
                            {
                                openAnswerQuestion
                                && <Input.TextArea
                                    onChange={(e) => {
                                        setAnswer(e.target.value)
                                    }}
                                />
                            }
                        </Row>
                        <Row justify={"space-between"}>
                            {
                                messageObj.answered
                                    ? <div>
                                        <img src={icSaved} style={{ marginRight: 8 }} />
                                        <b style={{ color: "#009933" }}>{t("e_learning.answered")}</b>
                                    </div>
                                    : <>
                                        <Button
                                            style={{
                                                padding: 0,
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                            type="link"
                                            onClick={() => setOpenAnswerQuestion(!openAnswerQuestion)}
                                        >
                                            {!!!openAnswerQuestion && <img style={{ marginRight: 8 }} src={icEmail} />}
                                            {openAnswerQuestion ? t("common.cancel") : t("e_learning.answer_to_email")}
                                        </Button>
                                        {
                                            openAnswerQuestion
                                            && <Button
                                                style={{
                                                    padding: 0,
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}
                                                type="link"
                                                onClick={() => replyToAMessage()}
                                            >
                                                <img style={{ marginRight: 8 }} src={icEmail} />
                                                {t("common.send")}
                                            </Button>
                                        }
                                    </>
                            }
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col>
                {
                    !!!messageObj.answered
                    && <Button
                        style={{
                            display: "flex",
                            alignItems: "center"
                        }}
                        type="text"
                        onClick={() => replyToAMessage(true)}
                    >
                        <img style={{ marginRight: 8 }} src={icSave} />
                        <span style={{ color: "#667380" }}>{t("e_learning.mark_as_answered")}</span>
                    </Button>
                }
            </Col>
        </Row>
    </div>
}