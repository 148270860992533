import { Button, Input, message } from "antd";
import { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import icSave from "../../img/ic-save.svg";
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';


export const CSDDsettingsCard = () => {
    const { t } = useTranslation();
    const [csddCredentials, setCsddCredentials] = useState({
        api_username: undefined as string | undefined,
        api_password: undefined as string | undefined,
        // api_sysguid: undefined as string | undefined,
    });

    const getSchool = () => {
        http.get(`school-info`).then((res: AxiosResponse) => {
            if (res.data.data)
                setCsddCredentials({
                    api_username: res.data.data.api_username ?? undefined,
                    api_password: res.data.data.api_password ?? undefined,
                    // api_sysguid: res.data.data.api_sysguid ?? undefined,
                })
        })
    };

    const saveSchoolInfo = () => {
        http.post(`change-school-info`, { ...csddCredentials }).then((res: AxiosResponse) => {
            message.success(t("notification.data_saved_successfuly"))
        }).catch(() => {
            message.error(t("errors.error"))
        })
    }

    useEffect(() => {
        getSchool();
    }, [])

    return <div className="shadow-card settings-card" style={{ padding: 30 }}>
        <h1 style={{ margin: "0 0 20px" }}>{t("settings.csdd_settings")}</h1>
        <div style={{ marginBottom: 20 }}>
            <label className="input-label">{t("settings.api_username")}</label>
            <Input
                value={csddCredentials.api_username}
                onChange={(e) => {
                    setCsddCredentials((prev): typeof csddCredentials => {
                        return {
                            ...prev,
                            api_username: e.target.value
                        }
                    })
                }}
            />
        </div>
        <div style={{ marginBottom: 20 }}>
            <label className="input-label">{t("settings.api_password")}</label>
            <Input
                value={csddCredentials.api_password}
                onChange={(e) => {
                    setCsddCredentials((prev): typeof csddCredentials => {
                        return {
                            ...prev,
                            api_password: e.target.value
                        }
                    })
                }}
            />
        </div>
        {/* <div style={{ marginBottom: 20 }}>
            <label className="input-label">CSDD sysGUID</label>
            <Input
                value={csddCredentials.api_sysguid}
                onChange={(e) => {
                    setCsddCredentials((prev): typeof csddCredentials => {
                        return {
                            ...prev,
                            api_sysguid: e.target.value
                        }
                    })
                }}
            />
        </div> */}
        <Button
            style={{ width: "100%", height: 38, display: "flex", alignItems: "center", justifyContent: "center" }}
            type="primary"
            onClick={saveSchoolInfo}
        >
            <img style={{ marginRight: 8 }} src={icSave} />
            {t("settings.save_changes")}
        </Button>
    </div>
}