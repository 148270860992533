import { Col, Row } from "antd"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"

export const ChapterTitleCard = ({ title, ind, chapterID, moduleID, categoryID, module }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return <div
        style={{ marginBottom: 6 }}
        onClick={() => navigate(`/modules/${categoryID}/${moduleID}/${chapterID}`)}
    >
        <Row className="shadow-card chapter-title-card" gutter={20}>
            <Col>
                {ind + 1}. {t("e_learning.chapter")}
            </Col>
            <Col>
                {title}
            </Col>
        </Row>
    </div>
}