import { Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { Category, Language } from '../../App';
import { Student } from '../Students/StudentsLayout';
import { useTranslation } from 'react-i18next';

type BulkAddStudentsTableProps = {
    availableStudents: Student[];
    freeSlots: number;
    returnSelectedStudents: (keys: React.Key[]) => void;
    selectedStudents: React.Key[];
}

export const BulkAddStudentsTable = ({ availableStudents, freeSlots, returnSelectedStudents, selectedStudents }: BulkAddStudentsTableProps) => {
    const { t } = useTranslation();
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log(newSelectedRowKeys)
        if (newSelectedRowKeys.length > freeSlots) {
            message.error(t("notification.group_dont_have_slots"));
            return;
        }
        returnSelectedStudents(newSelectedRowKeys)
    }

    const columns: ColumnsType<Student> = [
        {
            title: t("settings.name"),
            dataIndex: "first_name",
            key: "first_name",
            fixed: "left"
        },
        {
            title: t("settings.surname"),
            dataIndex: "last_name",
            key: "last_name"
        },
        {
            title: t("common.personal_id"),
            dataIndex: "personal_identity_number",
            key: "personal_identity_number"
        },
        {
            title: t("common.category"),
            dataIndex: "category",
            key: "category",
            render: (category: Category) => {
                return <span>{category.value} {t("common.category")}</span>
            }
        },
        {
            title: t("common.language"),
            dataIndex: "language",
            key: "language",
            render: (language: Language) => {
                return <span>{language?.iso?.toUpperCase()}</span>
            }
        },
        {
            title: t("settings.phone"),
            dataIndex: "phone_number",
            key: "phone"
        },
        {
            title: t("settings.email"),
            dataIndex: "email",
            key: "email"
        }
    ];

    return <>
        <h3>{t("groups.students_not_in_groups")}</h3>
        <Table
            size='small'
            rowKey={"id"}
            columns={columns}
            dataSource={availableStudents}
            scroll={{ x: 600 }}
            rowSelection={{
                selectedRowKeys: selectedStudents,
                onChange: onSelectChange
            }}
            pagination={false}
        />
    </>
}