import { Button } from "antd";
import { Header } from "../components/Header";
import icPlus from "../../img/ic-plus.svg";
import { UserCard } from "./UserCard";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { User } from "../../App";
import { AddEditUserModal } from "./AddEditUserModal";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type UsersLayoutProps = {
    title: string;
}

export const UsersLayout = ({ title }: UsersLayoutProps) => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [users, setUsers] = useState<User[] | []>([]);
    const [opened, setOpened] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>();

    const getUsers = () => {
        http.get(`school-admins`).then((res: AxiosResponse) => {
            setUsers(
                res.data.data.map((user: any): User => {
                    return {
                        id: user.id,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email: user.email,
                        phone: user.phone,
                        ruler_of_universe: user.ruler_of_universe
                    }
                })
            )
        })
    };

    useEffect(() => {
        getUsers();
        if (state?.create) {
            setOpened(true)
        }
    }, []);

    useEffect(() => {
        if (selectedUser !== undefined) {
            setOpened(true);
        }
    }, [selectedUser])

    return <div>
        <AddEditUserModal
            user={selectedUser}
            opened={opened}
            setOpened={(state: boolean) => setOpened(state)}
            refreshUsers={getUsers}
        />
        <Header
            title={title}
            counter={users.length}
            button={<Button
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
                type="primary"
                onClick={() => { setOpened(true); setSelectedUser(undefined) }}
            >
                <img src={icPlus} style={{ marginRight: 8 }} />
                {t("users.add_user")}
            </Button>
            }
        />
        <div
            style={{ padding: 20 }}
        >
            {
                users?.map((user: User): React.ReactNode => {
                    return <UserCard
                        key={user?.id}
                        user={user}
                        setSelectedUser={(user: User) => setSelectedUser(user)}
                    />
                })
            }
        </div>
    </div>
}