import { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { http } from "../../helpers/http"
import { ChaptersNavigation } from "./components/ChaptersNavigation";
import { LessonLayout } from "./LessonLayout";
import { Module } from "./ModulesLayout";
import { ReplyCard } from "./ReplyCard";

export const ChapterLayout = () => {
    const { catid, cid, mid, lid, iso } = useParams();
    const { state } = useLocation();
    const [chapter, setChapter] = useState({} as any);
    const [module, setModule] = useState({} as Module);

    const getChapter = () => {
        http.get(`chapter-info/${cid}`).then((res: any) => {
            console.log(res)
            setChapter(res.data.data)
        })
    };

    const getModule = () => {
        http.get(`learning-modules-list/${catid}/${mid}`).then((res: any) => {
            setModule(res.data[0])
        })
    };

    useEffect(() => {
        getModule();
    }, []);

    useEffect(() => {
        getChapter();
    }, [cid]);

    return <div style={{ padding: 20 }}>
        <div className="shadow-card" style={{ padding: 30 }}>
            <LessonLayout
                cid={cid}
                mid={mid}
                lid={lid}
                iso={iso}
                chapter={chapter}
                jumpTo={state?.jumpTo}
            />
            <ChaptersNavigation
                module={module}
                currentChapter={chapter}
                catid={catid}
            />
            {
                state?.message !== undefined
                && state?.message.e_chapter_id === Number(cid)
                && <ReplyCard 
                    messageObj={state?.message}
                />
            }
        </div>
    </div>
}