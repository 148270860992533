import { Button, Col, Input, Modal, Row, message } from 'antd';
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { User } from "../../App"
import { http } from "../../helpers/http";
import icAddUser from "../../img/ic-add-user.svg";
import { useTranslation } from 'react-i18next';

type AddEditUserModalProps = {
    user?: User;
    opened: boolean;
    setOpened: (state: boolean) => void;
    refreshUsers: () => void;
}

export const AddEditUserModal = ({ user, opened, setOpened, refreshUsers }: AddEditUserModalProps) => {
    const { t } = useTranslation();
    const [editUser, setEditUser] = useState<User | undefined>(user);

    const saveUser = () => {
        http.post(`school-admins`, {
            ...editUser
        }).then((res: AxiosResponse) => {
            refreshUsers();
            setOpened(false);
        }).catch(() => {
            message.error(t("errors.error"));
        })
    }

    useEffect(() => {
        if (user === undefined) {
            setEditUser({
                first_name: undefined,
                last_name: undefined,
                phone: undefined,
                email: undefined
            })
        } else {
            setEditUser(user)
        }
    }, [user])

    return <Modal
        open={opened}
        onCancel={() => setOpened(false)}
        footer={
            <Button
                type="primary"
                onClick={saveUser}
            >
                {
                    editUser === undefined
                        ? t("common.add")
                        : t("common.save")
                }
            </Button>
        }
    >
        <Row>
            <Col span={24} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <img src={icAddUser} />
                </div>
                <span style={{ fontSize: 20, color: "#303136", textAlign: "center" }}>
                    {user === undefined ? t("users.add_user") : t("users.edit_user")}
                </span>
            </Col>
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("settings.name")}</p>
            <Input
                style={{ marginBottom: 20 }}
                value={editUser?.first_name}
                onChange={(e) => {
                    setEditUser((prev: User | undefined): User => {
                        return {
                            ...prev,
                            first_name: e.target.value
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("settings.surname")}</p>
            <Input
                style={{ marginBottom: 20 }}
                value={editUser?.last_name}
                onChange={(e) => {
                    setEditUser((prev: User | undefined): User => {
                        return {
                            ...prev,
                            last_name: e.target.value
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("settings.phone")}</p>
            <Input
                style={{ marginBottom: 20 }}
                value={editUser?.phone}
                onChange={(e) => {
                    setEditUser((prev: User | undefined): User => {
                        return {
                            ...prev,
                            phone: e.target.value
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("settings.email")}</p>
            <Input
                style={{ marginBottom: 20 }}
                value={editUser?.email}
                onChange={(e) => {
                    setEditUser((prev: User | undefined): User => {
                        return {
                            ...prev,
                            email: e.target.value
                        }
                    })
                }}
            />
        </Row>
    </Modal>
}