import { Col, Row } from "antd"
import { BigMainButton } from "../components/BigMainButton"
import icStudyGroup from "../../img/ic-add-group.svg"
import icStudent from "../../img/ic-add-student.svg"
import icUser from "../../img/ic-add-user.svg"
import icStudentsGreen from "../../img/ic-sidebar-students-green.svg"
import icStudyGroupsGreen from "../../img/ic-sidebar-theory-green.svg"
import { SummaryCard } from "../components/SummaryCard"
import { Header } from "../components/Header"
import { http } from "../../helpers/http"
import { useEffect, useState } from "react"
import { AxiosResponse } from "axios"
import { User } from "../../App"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';

type DashboardLayoutProps = {
    title: string;
    user: User;
}

type ActiveStats = {
    groups: number;
    students: number;
}

export const DashboardLayout = ({ title, user }: DashboardLayoutProps) => {
    const { t } = useTranslation();
    const [activeStats, setActiveStats] = useState<ActiveStats>({
        groups: 0,
        students: 0
    })

    const getActiveStats = async () => {
        const students = await http.get(`active-student-counter`).then((res: AxiosResponse) => {
            console.log(res)
            return res.data.data
        })
        const groups = await http.get(`active-group-counter`).then((res: AxiosResponse) => {
            console.log(res)
            return res.data.data
        })
        setActiveStats({
            groups,
            students
        })
    };

    useEffect(() => {
        getActiveStats();
    }, []);

    return <>
        <Header
            title={title}
        />
        <div style={{ padding: 20 }}>
            <Row>
                <Col span={24}>
                    <div style={{ margin: "0 0 0 25px" }}>
                        <p>{t("common.greetings")},</p>
                        <h3>{user?.first_name} {user?.last_name}</h3>
                    </div>
                </Col>
            </Row>
            <Row gutter={[20, 20]} style={{ marginBottom: 60 }}>
                <Col md={8} span={24}>
                    <Link to="/study-groups" state={{ create: true }}>
                        <BigMainButton
                            title={t("navigation.make_new_group")}
                            icon={icStudyGroup}
                        />
                    </Link>
                </Col>
                <Col md={8} span={24}>
                    <Link to="/students" state={{ create: true }}>
                        <BigMainButton
                            title={t("navigation.make_new_student")}
                            icon={icStudent}
                        />
                    </Link>
                </Col>
                <Col md={8} span={24}>
                    <Link to="/users" state={{ create: true }}>
                        <BigMainButton
                            title={t("navigation.make_new_user")}
                            icon={icUser}
                        />
                    </Link>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <h2>{t("dashboard.summary")}</h2>
                </Col>
                <Col md={8} span={24}>
                    <SummaryCard
                        title={t("dashboard.active_students")}
                        icon={icStudentsGreen}
                        counter={activeStats.students}
                    />
                </Col>
                <Col md={8} span={24}>
                    <SummaryCard
                        title={t("dashboard.active_groups")}
                        icon={icStudyGroupsGreen}
                        counter={activeStats.groups}
                    />
                </Col>
            </Row>
        </div>
    </>
}