import { Category, Language } from "../../App";
import { Route, Routes } from "react-router-dom";
import { FilterParams, StudentFilters, StudentsTable } from "./StudentsTable";
import { StudentOpening } from "./StudentOpening";
import { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse } from "axios";
import { clearFilter, getFilter } from "../../helpers/filtersSaver";
import { PaginationProps } from "antd";

type StudentsLayoutProps = {
    title: string;
    categories: Category[];
    languages: Language[];
}

export type Student = {
    id: number;
    first_name: string;
    last_name: string;
    language?: Language;
    category?: Category;
    phone_number: string;
    email: string;
    personal_identity_number: string;
    csdd_nr?: string | null;
    sent_spams?: SentSpam[] | null;
    study_group?: number | null;
    reservation_type?: string;
    elearning_progress?: {
        max_lessons: number;
        done_lessons: number;
    },
    start_date?: string;
    first_login?: string;
};

type SentSpam = {
    failures: number;
    sent: number | null;
}

export type StudentsMeta = {
    current: number;
    total: number;
}

export type StudentGroupStatus = string;

export const StudentsLayout = ({ title, categories, languages }: StudentsLayoutProps) => {
    const [students, setStudents] = useState<Student[] | []>([]);
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    });
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState<StudentFilters>({});

    const getStudents = (page: number = pagination.current ?? 1, reqFilters: StudentFilters = filters) => {
        setFilters(reqFilters)
        setLoading(true);
        http.get(`students`, { params: { page: page, filters: { ...reqFilters } } }).then((res: AxiosResponse) => {
            const { data, meta } = res.data;
            setStudents(() => {
                return data.map((student: any): Student => {
                    return {
                        first_name: student.first_name,
                        last_name: student.last_name,
                        email: student.email,
                        phone_number: student.phone_number,
                        id: student.id,
                        personal_identity_number: student.personal_identity_number,
                        category: {
                            id: student?.category?.id,
                            value: student?.category?.value,
                            e_learning_lesson_counter: student?.category?.e_learning_lesson_counter
                        },
                        language: {
                            id: student?.language?.id,
                            iso: student?.language?.iso
                        },
                        csdd_nr: student?.csdd_nr,
                        sent_spams: student?.sent_spams,
                        study_group: student?.study_group,
                        elearning_progress: {
                            max_lessons: student.category.e_learning_lesson_counter,
                            done_lessons: student.module_counter
                        },
                        start_date: student.start_date,
                        first_login: student.first_login
                    };
                })
            })
            setPagination({
                current: meta.current_page,
                total: meta.total
            })
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    };

    const firstLoadStudents = () => {
        getStudents();
    }

    useEffect(() => {
        firstLoadStudents();
    }, []);

    return <>
        <Routes>
            <Route
                path="/"
                element={
                    <StudentsTable
                        categories={categories}
                        languages={languages}
                        title={title}
                        students={students}
                        getStudents={getStudents}
                        studentFilters={filters}
                        pagination={pagination}
                        loading={loading}
                    />
                }
            />
            <Route
                path=":id"
                element={
                    <StudentOpening
                        title={title}
                        students={students}
                        languages={languages}
                        refreshStudents={getStudents}
                        categories={categories}
                    />
                }
            />
        </Routes>

    </>
}
