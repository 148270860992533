import { Layout } from "antd"
import { Routes, Route } from "react-router-dom"
import "./Login.scss"
import { LoginForm } from "./LoginForm"
import { NewPasswordForm } from "./NewPasswordForm"
import { RenewPasswordForm } from "./RenewPasswordForm"

export const LoginLayout = () => {

    return <Layout className="login-layout">
        <h3>Skolas portāls</h3>
        <Routes>
            <Route
                path="/*"
                element={<LoginForm />}
            />
            <Route
                path="/new-password/:hash"
                element={<NewPasswordForm />}
            />
            <Route
                path="/renew-password"
                element={<RenewPasswordForm />}
            />
        </Routes>

    </Layout>
}