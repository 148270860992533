import { Button, Input, message } from "antd";
import { useEffect, useState } from "react";
import { User } from "../../App";
import { http } from "../../helpers/http";
import icSave from "../../img/ic-save.svg";
import { AxiosResponse, AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

type UserSettingsCardProps = {
    user: User;
    refreshUser: () => void;
}

export const UserSettingsCard = ({ user, refreshUser }: UserSettingsCardProps) => {
    const { t } = useTranslation();
    const [userSettings, setUserSettings] = useState<User>(user);
    const [password, setPassword] = useState({
        password: undefined as string | undefined,
        password_confirmation: undefined as string | undefined,
    })

    const saveUserSettings = () => {
        if (userSettings) {
            http.post(`school-admins`, { ...userSettings }).then((res: AxiosResponse) => {
                message.success(t("notification.data_saved_successfuly"));
                refreshUser();
            }).catch((err: AxiosError) => {
                message.error(err.response?.data?.errors?.hasOwnProperty("email") ? t("errors.e_mail_is_taken") : t("errors.error"));
            })
        }
    }

    const changePassword = () => {
        if (password.password !== password.password_confirmation) {
            message.error(t("login.new_password.passwords_doesnt_match"));
            return;
        }
        if (password.password === undefined ? false : password?.password?.length < 6) {
            message.error(t("login.new_password.min_password_length"));
            return;
        }
        http.post(`change-password`, { password: password.password, password_confirmation: password.password_confirmation }).then((res: AxiosResponse) => {
            message.success(t("login.new_password.password_changed_successfuly"));

        }).catch(() => {
            message.error(t("errors.error"))
        })
    }

    useEffect(() => {
        setUserSettings(user)
    }, [user])

    return <div className="shadow-card settings-card" style={{ padding: 30 }}>
        <h1 style={{ margin: "0 0 20px" }}>{t("settings.user_settings")}</h1>
        <div style={{ marginBottom: 20 }}>
            <label className="input-label">{t("settings.name")}</label>
            <Input
                value={userSettings?.first_name}
                onChange={(e) => {
                    setUserSettings((prev: User): User => {
                        return {
                            ...prev,
                            first_name: e.target.value
                        }
                    })
                }}
            />
        </div>
        <div style={{ marginBottom: 20 }}>
            <label className="input-label">{t("settings.surname")}</label>
            <Input
                value={userSettings?.last_name}
                onChange={(e) => {
                    setUserSettings((prev: User): User => {
                        return {
                            ...prev,
                            last_name: e.target.value
                        }
                    })
                }}
            />
        </div>
        <div style={{ marginBottom: 20 }}>
            <label className="input-label">{t("settings.email")}</label>
            <Input
                value={userSettings?.email}
                onChange={(e) => {
                    setUserSettings((prev: User): User => {
                        return {
                            ...prev,
                            email: e.target.value
                        }
                    })
                }}
            />
        </div>
        <div style={{ marginBottom: 20 }}>
            <label className="input-label">{t("settings.phone")}</label>
            <Input
                value={userSettings?.phone}
                onChange={(e) => {
                    setUserSettings((prev: User): User => {
                        return {
                            ...prev,
                            phone: e.target.value
                        }
                    })
                }}
            />
        </div>
        <Button
            style={{ width: "100%", height: 38, display: "flex", alignItems: "center", justifyContent: "center" }}
            type="primary"
            onClick={saveUserSettings}
        >
            <img style={{ marginRight: 8 }} src={icSave} />
            {t("settings.save_changes")}
        </Button>
        <h3 style={{ margin: "30px 0 20px" }}>{t("settings.change_password")}</h3>
        <div style={{ marginBottom: 20 }}>
            <label className="input-label">{t("login.new_password.new_password")}</label>
            <Input.Password
                value={password.password}
                onChange={(e) => {
                    setPassword((prev) => {
                        return {
                            ...prev,
                            password: e.target.value
                        }
                    })
                }}
            />
        </div>
        <div style={{ marginBottom: 30, paddingBottom: 30, borderBottom: "1px solid #F0F2F5" }}>
            <label className="input-label">{t("login.new_password.repeat_password")}</label>
            <Input.Password
                value={password.password_confirmation}
                onChange={(e) => {
                    setPassword((prev) => {
                        return {
                            ...prev,
                            password_confirmation: e.target.value
                        }
                    })
                }}
            />
        </div>
        <Button
            style={{ width: "100%", height: 38, display: "flex", alignItems: "center", justifyContent: "center" }}
            type="primary"
            disabled={password.password === undefined || password.password.length === 0}
            onClick={changePassword}
        >
            <img style={{ marginRight: 8 }} src={icSave} />
            {t("settings.save_changes")}
        </Button>
    </div>
}