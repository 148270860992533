import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';

type CategoryButtonProps = {
    title: string;
    id: number;
}

export const CategoryButton = ({ title, id }: CategoryButtonProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return <button
        className="shadow-card big-category-btn"
        onClick={() => navigate(`/modules/${id}`)}
    >
        {title} {t("common.category")}
    </button>
}