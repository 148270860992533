import './App.scss';
import { Button, Layout, Menu, MenuProps, message } from 'antd';
import icSteeringWheel from '../src/img/ic-steering-wheel.svg';
import dashboardIcon from '../src/img/ic-sidebar-dashboard.svg';
import dashboardIconActive from '../src/img/ic-sidebar-dashboard-active.svg';
import theoryIcon from '../src/img/ic-sidebar-theory.svg';
import theoryIconActive from '../src/img/ic-sidebar-theory-active.svg';
import questionIcon from '../src/img/ic-sidebar-question.svg';
import questionIconActive from '../src/img/ic-sidebar-question-active.svg';
import studentsIcon from '../src/img/ic-sidebar-students.svg';
import studentsIconActive from '../src/img/ic-sidebar-students-active.svg';
import modulesIcon from '../src/img/ic-sidebar-modules.svg';
import modulesIconActive from '../src/img/ic-sidebar-modules-active.svg';
import dataIcon from '../src/img/ic-sidebar-data.svg';
import dataIconActive from '../src/img/ic-sidebar-data-active.svg';
import settingsIcon from '../src/img/ic-sidebar-settings.svg';
import settingsIconActive from '../src/img/ic-sidebar-settings-active.svg';
import { Link, Route, Routes, useLocation, useMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { StudyGroupsLayout } from './pages/StudyGroups/StudyGroupsLayout';
import { Header } from './pages/components/Header';
import { DashboardLayout } from './pages/Dashboard/DashboardLayout';
import { LoginLayout } from './pages/Login/LoginLayout';
import { http } from './helpers/http';
import { AxiosResponse } from 'axios';
import { StudentsLayout } from './pages/Students/StudentsLayout';
import { MessagesLayout } from './pages/Messages/MessagesLayout';
import { title } from 'process';
import { UsersLayout } from './pages/Users/UsersLayout';
import { SettingsLayout } from './pages/Settings/SettingsLayout';
import { LearningModulesLayout } from './pages/LearningModules/LearningModulesLayout';
import { useTranslation } from 'react-i18next';
import { LanguageSwitch } from './pages/components/LanguageSwitch';

export type User = {
    id?: number | undefined;
    first_name?: string | undefined;
    last_name?: string | undefined;
    email?: string | undefined;
    phone?: string | undefined;
    ruler_of_universe?: 1 | 0 | undefined
} | null;

type School = {
    name: string,
    reg_nr: string
} | null;

export type Category = {
    id?: number;
    value?: string;
    e_learning_lesson_counter?: number;
}

export type Language = {
    id?: number;
    iso?: string;
}

const App = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(0);
    const [logedIn, setLogedIn] = useState<boolean | undefined>(undefined);
    const [user, setUser] = useState<User>(null);
    const [school, setSchool] = useState<School>(null);
    const [categories, setCategories] = useState<Category[] | []>([]);
    const [languages, setLanguages] = useState<Language[] | []>([]);
    const [newQuestions, setNewQuestions] = useState(0);

    const menuLinksPremade = [
        { icon: dashboardIcon, iconSelected: dashboardIconActive, to: "/", title: t("common.beginning") },
        { icon: theoryIcon, iconSelected: theoryIconActive, to: "/study-groups", title: t("navigation.study_groups") },
        { icon: questionIcon, iconSelected: questionIconActive, to: "/messages", title: t("navigation.asked_questions") },
        { icon: studentsIcon, iconSelected: studentsIconActive, to: "/students", title: t("navigation.students") },
        { icon: modulesIcon, iconSelected: modulesIconActive, to: "/modules", title: t("navigation.learning_modules") },
        { icon: dataIcon, iconSelected: dataIconActive, to: "/users", title: t("navigation.users") },
        { icon: settingsIcon, iconSelected: settingsIconActive, to: "/settings", title: t("navigation.settings") },
    ];

    const logout = () => {
        sessionStorage.clear();
        window.location.href = "/";
    };

    const getSelfInfo = () => {
        http.get('self').then((res: AxiosResponse) => {
            console.log(res)
            const { data } = res.data;
            setUser({
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone,
                ruler_of_universe: data.ruler_of_universe
            })
            setSchool({
                name: data.school.name,
                reg_nr: data.school.reg_nr
            })
        }).catch((err: any) => {
            message.error(t("errors.error_loading_data"))
        })
    };

    const getClassifiers = async () => {
        await http.get('categories').then((res: AxiosResponse): void => {
            const { data } = res.data;
            if (data.length === 0) return;
            setCategories(data.map((item: Category): Category => {
                return {
                    id: item.id,
                    value: item.value
                }
            }))
        });
        await http.get('languages').then((res: AxiosResponse): void => {
            const { data } = res.data;
            if (data.length === 0) return;
            setLanguages(data.map((item: Language): Language => {
                return {
                    id: item.id,
                    iso: item.iso
                }
            }))
        });
    };

    const getNotAnsweredMessagesCount = () => {
        http.get(`school-elearning-student-questions`, {
            params: {
                skip_shit: 1
            }
        }).then((res: AxiosResponse) => {
            setNewQuestions(res.data.not_answered_total)
        })
    }

    useEffect(() => {
        const sp = new URLSearchParams(window.location.search);
        const savedToken = sp.get("bt");
        if (savedToken != null) {
            sessionStorage.setItem("bearer_token", savedToken);
            window.location.href = "/";
            return;
        }
        const hasToken = sessionStorage.getItem("bearer_token");
        if (hasToken) {
            const token = sessionStorage.getItem("bearer_token");
            http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            setLogedIn(true)
        }
        else setLogedIn(false);
    }, []);

    useEffect(() => {
        if (logedIn) {
            getSelfInfo();
            getClassifiers();
            getNotAnsweredMessagesCount();
        }
    }, [logedIn]);

    useEffect(() => {
        setSelectedKey(() => {
            return menuLinksPremade.findIndex((el: typeof menuLinksPremade[0]) => {
                return el.to.split("/")[1] === location.pathname.split("/")[1]
            })
        })
    }, [location])

    return logedIn === undefined
        ? <></>
        : !logedIn
            ? <LoginLayout />
            : <Layout
                style={{ minHeight: "100vh", overflow: "hidden" }}
            >
                <Layout.Sider
                    breakpoint={'lg'}
                    collapsedWidth={0}
                    style={{ position: "fixed", zIndex: 100, height: "100vh" }}
                    width={240}
                >
                    <div>
                        <div className="logo">
                            <div className='logo-img-wrapper'>
                                <img src={icSteeringWheel} />
                            </div>
                            {school?.name}
                        </div>
                        <Menu
                            theme='dark'
                            selectedKeys={[String(selectedKey)]}
                            onSelect={(e) => setSelectedKey(Number(e.key))}
                            items={
                                menuLinksPremade?.map((link: any) => {
                                    console.log(link.to, user)
                                    if (link.to === "/users" && !!!user?.ruler_of_universe) {
                                        return undefined
                                    }
                                    return link
                                }).filter((link: any) => link !== undefined).map((link: any, index: any) => {
                                    const active = link.to.split("/")[1] === location.pathname.split("/")[1]
                                    return {
                                        label: <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            <img style={{ marginRight: 12 }} src={active ? link.iconSelected : link.icon} />
                                            <Link to={link.to}>{link.title}</Link>
                                            {
                                                link.to === "/messages"
                                                && <div
                                                    style={{
                                                        width: 24,
                                                        height: 24,
                                                        borderRadius: 4,
                                                        backgroundColor: "#0883EF",
                                                        color: "#FFFFFF",
                                                        lineHeight: "14px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: 11,
                                                        fontWeight: "bold",
                                                        marginLeft: 8
                                                    }}
                                                >
                                                    {newQuestions}
                                                </div>
                                            }
                                        </div>,
                                        key: index
                                    }
                                }) as MenuProps['items']
                            }
                        />
                    </div>
                    <div
                        style={{
                            padding: "0 20px 30px",
                        }}
                    >
                        {/* <LanguageSwitch /> */}
                        <p style={{ color: "#FBFBFC", marginBottom: 2, fontWeight: "bold" }}>{user?.first_name} {user?.last_name}</p>
                        <Button
                            type="text"
                            style={{
                                color: "#C5C7CA",
                                fontSize: 12,
                                lineHeight: "18px",
                                textAlign: "left",
                                width: "auto",
                                padding: 0,
                                marginBottom: 30
                            }}
                            onClick={logout}
                        >
                            {t("common.logout")}
                        </Button>
                        {/* <p style={{ color: "#FBFBFC", margin: 0, fontSize: 12 }}>Powered by E-apmācība.lv</p> */}
                    </div>

                </Layout.Sider>
                <Layout className='main-section'>
                    <Routes>
                        <Route
                            path="/"
                            element={<DashboardLayout title={menuLinksPremade[selectedKey].title} user={user} />}
                        />
                        <Route
                            path="/study-groups/*"
                            element={
                                <StudyGroupsLayout
                                    categories={categories}
                                    languages={languages}
                                    title={menuLinksPremade[selectedKey].title}
                                />
                            }
                        />
                        <Route
                            path="/messages/*"
                            element={
                                <MessagesLayout
                                    title={menuLinksPremade[selectedKey].title}
                                    categories={categories}
                                    refreshCounter={getNotAnsweredMessagesCount}
                                />
                            }
                        />
                        <Route
                            path="/students/*"
                            element={
                                <StudentsLayout
                                    categories={categories}
                                    languages={languages}
                                    title={menuLinksPremade[selectedKey].title}
                                />
                            }
                        />
                        <Route
                            path="/modules/*"
                            element={
                                <LearningModulesLayout
                                    title={menuLinksPremade[selectedKey].title}
                                />
                            }
                        />
                        {
                            !!user?.ruler_of_universe
                            && <Route
                                path="/users/*"
                                element={
                                    <UsersLayout
                                        title={menuLinksPremade[selectedKey].title}
                                    />
                                }
                            />
                        }
                        <Route
                            path="/settings"
                            element={
                                <SettingsLayout
                                    title={menuLinksPremade[selectedKey].title}
                                    user={user}
                                    refreshUser={getSelfInfo}
                                />
                            }
                        />
                    </Routes>
                </Layout>
            </Layout >
}

export default App;
