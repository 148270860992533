import { Button, Checkbox, Col, Input, message, Modal, Row, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Language, Category } from '../../App';
import { http } from '../../helpers/http';
import { AxiosResponse, AxiosError } from 'axios';
import { StudyGroup, GroupStatus } from '../StudyGroups/StudyGroupsLayout';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Student } from '../Students/StudentsLayout';
import { StudentTemplate } from '../Students/CreateStudentModal';
import { BulkAddStudentsTable } from './BulkAddStudentsTable';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

type AddStudentsToGroupModalProps = {
    open: boolean;
    setOpen: (state: boolean) => void;
    group: StudyGroup | null;
    languages: Language[];
    refreshStudents: () => void;
    refreshStudyGroups: () => void;
}

type StudentResData = {
    first_name: string;
    last_name: string;
    category: Category;
    language: Language;
    personal_identity_number: string;
    csdd_nr: string;
    email: string;
    phone_number: string;
    id: number
}

export const AddStudentsToGroupModal = ({ open, setOpen, group, languages, refreshStudents, refreshStudyGroups }: AddStudentsToGroupModalProps) => {
    const { t } = useTranslation();
    const [availableStudents, setAvailableStudents] = useState<Student[] | []>([]);
    const [newStudent, setNewStudent] = useState<StudentTemplate>({
        category_id: null,
        first_name: null,
        last_name: null,
        personal_identity_number: null,
        email: null,
        phone: null,
        language_id: null
    });
    const [disabledAddNewStudent, setDisabledAddNewStudent] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState<React.Key[]>([]);

    const getStudents = () => {
        http.get(`school-orphans`, {
            params: {
                category: group?.category?.id
            }
        }).then((res: AxiosResponse) => {
            setAvailableStudents(
                res.data.data.map((student: StudentResData): Student => {
                    return {
                        first_name: student?.first_name,
                        last_name: student?.last_name,
                        category: {
                            id: student?.category?.id,
                            value: student?.category?.value
                        },
                        language: {
                            id: student?.language?.id,
                            iso: student?.language?.iso
                        },
                        personal_identity_number: student.personal_identity_number,
                        csdd_nr: student?.csdd_nr,
                        email: student?.email,
                        phone_number: student?.phone_number,
                        id: student?.id
                    }
                })
            )
        })
    };

    const resetNewStudent = () => {
        setNewStudent({
            category_id: group?.category?.id === undefined ? null : group?.category?.id,
            first_name: null,
            last_name: null,
            personal_identity_number: null,
            email: null,
            phone: null,
            language_id: null
        })
    };

    const createAndAddStudentToGroup = () => {
        if (!group) return;
        let sendSpam: 1 | 0 = 0;
        Modal.confirm({
            onOk: () => {
                http.post(`add-student-and-stick-in-group/${group.id}`, { ...newStudent, with_spam: sendSpam }).then((res: AxiosResponse) => {
                    console.log(res)
                    refreshStudyGroups();
                    refreshStudents();
                    resetNewStudent();
                }).catch((err: AxiosError) => {
                    message.error(t("errors.error"))
                })
            },
            content: <div>
                <b>Nosūtīt studentam piekļuves saiti: </b>
                <Checkbox
                    onChange={(e: CheckboxChangeEvent) => {
                        sendSpam = e.target.checked ? 1 : 0
                    }}
                />
            </div>
        })

    };

    const addStudentsToGroup = () => {
        if (!group) return;
        let sendSpam: 1 | 0 = 0
        Modal.confirm({
            onOk: () => {
                http.post(`add-students-bulk/${group.id}`, { students: selectedStudents, with_spam: sendSpam }).then((res: AxiosResponse) => {
                    console.log(res);
                    refreshStudyGroups();
                    refreshStudents();
                    setOpen(false);
                })
            },
            content: <div>
                <b>{t("students.send_student_access")}</b>
                <Checkbox
                    onChange={(e: CheckboxChangeEvent) => {
                        sendSpam = e.target.checked ? 1 : 0
                    }}
                />
            </div>
        })
    }

    useEffect(() => {
        if (open) {
            getStudents();
        }
    }, [open]);

    useEffect(() => {
        setNewStudent((prev: StudentTemplate): StudentTemplate => {
            return {
                ...prev,
                category_id: group?.category?.id === undefined ? -1 : group?.category?.id
            }
        })
    }, [group])

    useEffect(() => {
        setDisabledAddNewStudent(
            newStudent.first_name === null
            || newStudent.last_name === null
            || newStudent.email === null
            || newStudent.phone === null
            || newStudent.category_id === null
            || newStudent.language_id === null
            || newStudent.personal_identity_number === null
        )
        console.log(newStudent)
    }, [newStudent])

    return <Modal
        open={open}
        onCancel={() => { setOpen(false); resetNewStudent(); }}
        width={1200}
        title={t("groups.add_students_to_group")}
        footer={<Row gutter={[10, 10]}>
            <Col md={12} span={24}>
                <Button
                    style={{ width: "100%" }}
                    onClick={() => setOpen(false)}
                >
                    {t("common.cancel_action")}
                </Button>
            </Col>
            <Col md={12} span={24}>
                <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={addStudentsToGroup}
                    disabled={selectedStudents.length === 0}
                >
                    {t("groups.add_selected_students")}
                </Button>
            </Col>
        </Row>}
    >
        <Row gutter={[10, 10]} justify={'space-between'} align="bottom">
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("settings.name")}</p>
                <Input
                    style={{ width: 140 }}
                    value={newStudent.first_name ?? undefined}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewStudent((prev: StudentTemplate): StudentTemplate => {
                            return {
                                ...prev,
                                first_name: event.target.value.length > 0 ? event.target.value : null
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("settings.surname")}</p>
                <Input
                    style={{ width: 140 }}
                    value={newStudent.last_name ?? undefined}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewStudent((prev: StudentTemplate): StudentTemplate => {
                            return {
                                ...prev,
                                last_name: event.target.value.length > 0 ? event.target.value : null
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.personal_id")}</p>
                <Input
                    style={{ width: 140 }}
                    value={newStudent.personal_identity_number ?? undefined}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewStudent((prev: StudentTemplate): StudentTemplate => {
                            return {
                                ...prev,
                                personal_identity_number: event.target.value.length > 0 ? event.target.value : null
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("settings.phone")}</p>
                <Input
                    style={{ width: 140 }}
                    value={newStudent.phone ?? undefined}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewStudent((prev: StudentTemplate): StudentTemplate => {
                            return {
                                ...prev,
                                phone: event.target.value.length > 0 ? event.target.value : null
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("settings.email")}</p>
                <Input
                    style={{ width: 140 }}
                    value={newStudent.email ?? undefined}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewStudent((prev: StudentTemplate): StudentTemplate => {
                            return {
                                ...prev,
                                email: event.target.value.length > 0 ? event.target.value : null
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.language")}</p>
                <Select
                    style={{ width: 140 }}
                    value={newStudent.language_id}
                    onChange={(e: number) => {
                        setNewStudent((prev: StudentTemplate): StudentTemplate => {
                            return {
                                ...prev,
                                language_id: e
                            }
                        })
                    }}
                >
                    {
                        languages.map((language: Language) => {
                            return <Select.Option key={language.id} value={language.id}>{language.iso?.toUpperCase()}</Select.Option>
                        })
                    }
                </Select>
            </Col>
            <Button
                style={{
                    backgroundColor: disabledAddNewStudent ? "#dbdbdb" : "black",
                    alignSelf: "end",
                    margin: "0 0 0 auto"
                }}
                type="primary"
                disabled={disabledAddNewStudent}
                onClick={createAndAddStudentToGroup}
            >
                {t("groups.add_new_student")}
            </Button>
        </Row>
        <BulkAddStudentsTable
            availableStudents={availableStudents}
            freeSlots={group?.free_slots ?? 0}
            returnSelectedStudents={setSelectedStudents}
            selectedStudents={selectedStudents}
        />
    </Modal>
}