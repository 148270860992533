import { Row } from 'antd';
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Chapter, Module } from '../ModulesLayout';

type ChaptersNavigationProps = {
    module: Module;
    currentChapter: Chapter;
    catid: string | undefined;
}

export const ChaptersNavigation = ({ module, currentChapter, catid }: ChaptersNavigationProps) => {
    const { t } = useTranslation();
    const [navigation, setNavigation] = useState({
        prev: null as Chapter | null,
        next: null as Chapter | null,
    })


    const calculateNav = () => {
        const first = currentChapter.ind === 0;
        const last = currentChapter.ind === module?.chapters?.length - 1;
        setNavigation({
            prev: first ? null : module?.chapters?.[currentChapter.ind - 1],
            next: last ? null : module?.chapters?.[currentChapter.ind + 1]
        })
    };

    useEffect(() => {
        if (!currentChapter || currentChapter === undefined) return;
        calculateNav();
    }, [currentChapter]);

    return <Row justify="space-between" style={{ marginTop: 30 }}>
        <div>
            {
                navigation.prev
                && <Link
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "11px 20px",
                    }}
                    to={`/modules/${catid}/${module.id}/${navigation.prev ? navigation.prev?.id : 1}`}
                    state={{ module: module }}
                >
                    <span
                        style={{
                            display: "block",
                            color: "black",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 6
                        }}
                    >
                        {t("e_learning.previous")}
                    </span>
                    <small style={{ color: "black" }}>{navigation.prev?.title_lv}</small>
                </Link>
            }
        </div>
        <div>
            {
                navigation.next
                && <Link
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "11px 20px",
                        alignItems: "end"
                    }}
                    to={`/modules/${catid}/${module.id}/${navigation.next ? navigation.next?.id : 1}`}
                    state={{ module: module }}
                >
                    <span
                        style={{
                            display: "block",
                            color: "black",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 6
                        }}
                    >
                        {t("e_learning.next")}
                    </span>
                    <small style={{ color: "black" }}>{navigation.next?.title_lv}</small>
                </Link>
            }
        </div>
    </Row>
}
