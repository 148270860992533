import { Row, Col, Input, Select, Button } from "antd";
import { useState } from "react";
import { Category, Language } from "../../App";
import { StudentFilters } from "./StudentsTable";
import { useTranslation } from 'react-i18next';

type StudentsFiltersProps = {
    categories: Category[];
    languages: Language[];
    getStudents: (page: number, filters: StudentFilters) => void;
    initialFilters: StudentFilters;
}

export const StudentsFilters = ({ categories, languages, getStudents, initialFilters }: StudentsFiltersProps) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<StudentFilters>(initialFilters);


    const handleFiltersBar = () => {
        setFilters(filters);
        getStudents(1, filters);
    };

    const resetFilters = () => {
        setFilters({});
        getStudents(1, {});
    };

    return <Row className="shadow-card" style={{ padding: 20 }} align="bottom" justify={"space-between"}>
        <Row gutter={10}>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("settings.name")}</p>
                <Input
                    style={{ width: 140 }}
                    value={filters.first_name?.[0]}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFilters((prev: StudentFilters | undefined): StudentFilters => {
                            if (event.target.value.length === 0) {
                                const copyPrev: StudentFilters = JSON.parse(JSON.stringify(prev));
                                delete copyPrev.first_name;
                                return copyPrev;
                            }
                            return {
                                ...prev,
                                first_name: [event.target.value]
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("settings.surname")}</p>
                <Input
                    style={{ width: 140 }}
                    value={filters.last_name?.[0]}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFilters((prev: StudentFilters | undefined): StudentFilters => {
                            if (event.target.value.length === 0) {
                                const copyPrev: StudentFilters = JSON.parse(JSON.stringify(prev));
                                delete copyPrev.last_name;
                                return copyPrev;
                            }
                            return {
                                ...prev,
                                last_name: [event.target.value]
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.personal_id")}</p>
                <Input
                    style={{ width: 140 }}
                    value={filters.personal_identity_number?.[0]}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFilters((prev: StudentFilters | undefined): StudentFilters => {
                            if (event.target.value.length === 0) {
                                const copyPrev: StudentFilters = JSON.parse(JSON.stringify(prev));
                                delete copyPrev.personal_identity_number;
                                return copyPrev;
                            }
                            return {
                                ...prev,
                                personal_identity_number: [event.target.value]
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("groups.csdd_group")}</p>
                <Input
                    style={{ width: 140 }}
                    value={filters.csdd_nr?.[0]}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFilters((prev: StudentFilters | undefined): StudentFilters => {
                            if (event.target.value.length === 0) {
                                const copyPrev: StudentFilters = JSON.parse(JSON.stringify(prev));
                                delete copyPrev.csdd_nr;
                                return copyPrev;
                            }
                            return {
                                ...prev,
                                csdd_nr: [event.target.value]
                            }
                        })
                    }}
                />
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.category")}</p>
                <Select
                    style={{ width: 140 }}
                    value={Number(filters.category?.[0]) || null}
                    onChange={(e: number) => {
                        setFilters((prev: StudentFilters | undefined): StudentFilters => {
                            if (e === 0) {
                                const copyPrev: StudentFilters = JSON.parse(JSON.stringify(prev));
                                delete copyPrev.category;
                                return copyPrev;
                            }
                            return {
                                ...prev,
                                category: [String(e)]
                            }
                        })
                    }}
                >
                    {filters?.hasOwnProperty("category") && <Select.Option value={0}> </Select.Option>}
                    {
                        categories.map((category: Category) => {
                            return <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>
                        })
                    }
                </Select>
            </Col>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.language")}</p>
                <Select
                    style={{ width: 140 }}
                    value={Number(filters.language?.[0]) || null}
                    onChange={(e: number) => {
                        setFilters((prev: StudentFilters | undefined): StudentFilters => {
                            if (e === 0) {
                                const copyPrev: StudentFilters = JSON.parse(JSON.stringify(prev));
                                delete copyPrev.language;
                                return copyPrev;
                            }
                            return {
                                ...prev,
                                language: [String(e)]
                            }
                        })
                    }}
                >
                    {filters?.hasOwnProperty("language") && <Select.Option value={0}> </Select.Option>}
                    {
                        languages.map((language: Language) => {
                            return <Select.Option key={language.id} value={language.id}>{language.iso?.toUpperCase()}</Select.Option>
                        })
                    }
                </Select>
            </Col>

        </Row>
        <div>
            {
                Object.keys(filters).length > 0
                && <Button
                    style={{ marginRight: 10 }}
                    onClick={resetFilters}
                >
                    {t("filters.erase_filters")}
                </Button>
            }
            <Button
                style={{ backgroundColor: "black" }}
                type="primary"
                onClick={handleFiltersBar}
            >
                {t("filters.search")}
            </Button>
        </div>
    </Row>
}