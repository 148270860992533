import { Button, Row } from "antd"
import React, { FC, ReactNode, useEffect } from "react";
import "./Header.scss";

interface HeaderProps {
    title: string | React.ReactNode,
    counter?: number,
    button?: ReactNode
}

export const Header = ({ title, counter, button }: HeaderProps) => {

    useEffect(() => {
        console.log(counter)
    }, [counter])

    return <Row className="header-row" justify={"space-between"} align="middle">
        <div>
            <div style={{ display: "inline-flex", alignItems: "center", fontWeight: "bold" }}>{title}</div>
            {
                counter !== undefined
                && <b className="counter">{counter}</b>
            }
        </div>
        {button}
    </Row>
}