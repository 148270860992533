import { Col, Row } from "antd";
import { Header } from "../components/Header";
import { UserSettingsCard } from "./UserSettingsCard";
import "./Settings.scss";
import { CSDDsettingsCard } from './CSDDsettingsCard';
import { User } from "../../App";

type SettingsLayoutProps = {
    title: string;
    user: User;
    refreshUser: () => void;
}

export const SettingsLayout = ({ title, user, refreshUser }: SettingsLayoutProps) => {

    return <div>
        <Header
            title={title}
        />
        <div style={{ padding: 20 }}>
            <Row gutter={[20, 20]}>
                <Col md={12} span={24}>
                    <UserSettingsCard
                        user={user}
                        refreshUser={refreshUser}
                    />
                </Col>
                <Col md={12} span={24}>
                    {
                        !!user?.ruler_of_universe
                        && <CSDDsettingsCard

                        />
                    }
                </Col>
            </Row>
        </div>
    </div>
}