import { Header } from "../components/Header";
import "./Messages.scss";
import { Col, Input, Pagination, PaginationProps, Row, Select, Button } from 'antd';
import { MessageCard } from "./MessageCard";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';
import { useEffect, useState } from "react";
import { Category, Language } from "../../App";
import { useTranslation } from 'react-i18next';

type MessagesLayoutProps = {
    title: string;
    categories: Category[];
    refreshCounter: () => void;
}

export type Message = {
    id: number;
    ind: number;
    e_chapter_id: string;
    e_module_id: string;
    category_id: number;
    category: string;
    answered: 1 | 0;
    answer: string;
    csdd_nr: string;
    student: string;
    email: string;
    phone_number: string;
    question: string;
    created_at: string;
    language_id: number;
    chapter_title_lv: string;
    chapter_title_ru: string;
    chapter_title_en: string;
    seconds: number;
}

type MessagesFilter = {
    category_id?: number[];
    student?: string[];
}

export const MessagesLayout = ({ title, categories, refreshCounter }: MessagesLayoutProps) => {
    const { t } = useTranslation();
    const [messages, setMessages] = useState<Message[]>([]);
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        total: 0,
    });
    const [filters, setFilters] = useState<MessagesFilter>({
        category_id: [],
        student: []
    });
    const [languages, setLanguages] = useState<Language[]>([]);

    const getLanguages = () => {
        http.get("languages").then((res: AxiosResponse) => {
            setLanguages(
                res.data.data?.map((language: Language): Language => {
                    return {
                        id: language.id,
                        iso: language.iso
                    }
                })
            )
        })
    }

    const getMessages = (page: number = 1, filtersObj: MessagesFilter = filters) => {
        refreshCounter();
        http.get(`school-elearning-student-questions`, {
            params: {
                page: page,
                filters: filtersObj
            }
        }).then((res: AxiosResponse) => {
            console.log(res)
            setPagination({
                current: Number(res.data.page),
                total: res.data.total
            })
            setMessages(
                res.data.data.map((message: any): Message => {
                    return {
                        id: message.id,
                        ind: message.ind,
                        e_chapter_id: message.e_chapter_id,
                        e_module_id: message.e_module_id,
                        category_id: message.category_id,
                        category: message.category,
                        answered: message.answered,
                        answer: message.answer,
                        csdd_nr: message.csdd_nr,
                        student: message.student,
                        email: message.email,
                        phone_number: message.phone_number,
                        question: message.question,
                        created_at: message.created_at,
                        language_id: message.language_id,
                        chapter_title_lv: message.chapter_title_lv,
                        chapter_title_ru: message.chapter_title_ru,
                        chapter_title_en: message.chapter_title_en,
                        seconds: message.seconds
                    }
                })
            )
        })
    };

    const handlePagination = (page: number) => {
        console.log(page)
        getMessages(page)
    }

    useEffect(() => {
        getMessages();
        getLanguages();
    }, []);

    return <div>
        <Header
            title={title}
            counter={0}
        />
        <div style={{ padding: 20 }}>
            <Row>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <Row
                        className="shadow-card messages-layout"
                        style={{ padding: 30 }}
                        align="bottom"
                        justify={"center"}
                    >
                        <Col span={24}>
                            <h1>{t("navigation.asked_questions")}</h1>
                        </Col>
                        <Col span={24} className="filters-row">
                            <Row justify="space-between">
                                <Col>
                                    <Row gutter={10} align="bottom">
                                        <Col>
                                            <p>{t("common.category")}</p>
                                            <Select
                                                style={{ width: 200 }}
                                                value={filters.category_id?.[0] ?? undefined}
                                                onChange={(e: number) => {
                                                    setFilters((prev: MessagesFilter): MessagesFilter => {
                                                        return {
                                                            ...prev,
                                                            category_id: [e]
                                                        }
                                                    })
                                                }}
                                            >
                                                {
                                                    categories.map((category: Category): React.ReactNode => {
                                                        return <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                        <Col>
                                            <p>{t("common.student")}</p>
                                            <Input
                                                style={{ width: 200 }}
                                                value={filters.student?.[0] ?? undefined}
                                                onChange={(e) => {
                                                    setFilters((prev: MessagesFilter): MessagesFilter => {
                                                        return {
                                                            ...prev,
                                                            student: [e.target.value]
                                                        }
                                                    })
                                                }}
                                            />
                                        </Col>
                                        <Button
                                            type="primary"
                                            onClick={() => getMessages()}
                                        >
                                            Meklēt
                                        </Button>
                                        <Button
                                            style={{ marginLeft: 8 }}
                                            onClick={() => {
                                                const newFilters = {
                                                    category_id: [],
                                                    student: []
                                                }
                                                setFilters(newFilters);
                                                getMessages(1, newFilters)
                                            }}
                                        >
                                            Dzēst filtrus
                                        </Button>
                                    </Row>
                                </Col>
                                {/* <Col>
                                    <p>Rādīt lapā</p>
                                    <Select
                                        style={{ width: 100 }}
                                    >

                                    </Select>
                                </Col> */}
                            </Row>
                        </Col>
                        <Col span={24}>
                            {
                                messages.map((message: Message): React.ReactNode => {
                                    return <MessageCard
                                        key={message.id}
                                        messageObj={message}
                                        refreshMessages={() => { getMessages() }}
                                        language={languages.find((language: Language) => language.id === message.language_id)}
                                    />
                                })
                            }
                        </Col>
                        <Pagination
                            style={{ marginTop: 30 }}
                            current={pagination.current}
                            pageSize={15}
                            total={pagination.total}
                            showTotal={(total) => { return t("common.total_questions", { count: total }) as string }}
                            onChange={handlePagination}
                        />
                    </Row>
                </Col>
            </Row>
        </div>
    </div>
}