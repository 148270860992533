import { Button, Row, Col, Table } from "antd";
import { PaginationProps } from "antd/es/pagination";
import { ColumnsType } from "antd/es/table";
import { useState, useEffect } from "react";
import { Category, Language } from "../../App";
import { http } from "../../helpers/http";
import { Header } from "../components/Header";
import { AddStudentToGroupModal } from "./AddStudentToGroupModal";
import { Student } from "./StudentsLayout";
import icPlus from "../../img/ic-plus.svg";
import { Link, useLocation } from 'react-router-dom';
import { CreateStudentModal } from "./CreateStudentModal";
import { StudentsFilters } from "./StudentsFilters";
import { useTranslation } from 'react-i18next';

type StudentsTableProps = {
    title: string;
    categories: Category[];
    languages: Language[];
    students: Student[];
    getStudents: (page?: number, reqFilters?: StudentFilters) => void;
    pagination: PaginationProps;
    studentFilters: StudentFilters;
    loading: boolean;
}

export type StudentFilters = {
    first_name?: string[];
    last_name?: string[];
    personal_identity_number?: string[];
    csdd_nr?: string[];
    category?: string[];
    language?: string[];
}

export type FilterParams = {
    params: {
        page: number;
        filters?: StudentFilters;
    }
}

export const StudentsTable = ({ title, categories, languages, students, getStudents, studentFilters, pagination, loading }: StudentsTableProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [addStudentToGroupOpened, setAddStudentToGroupOpened] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState<Student | null | undefined>(null);

    const handleTableChange = (pagination: PaginationProps) => {
        getStudents(pagination.current ?? 1)
    };

    const columns: ColumnsType<Student> = [
        {
            title: t("settings.name"),
            dataIndex: "first_name",
            key: "first_name",
            fixed: "left"
        },
        {
            title: t("settings.surname"),
            dataIndex: "last_name",
            key: "last_name"
        },
        {
            title: t("common.personal_id"),
            dataIndex: "personal_identity_number",
            key: "personal_identity_number"
        },
        {
            title: t("groups.csdd_group"),
            dataIndex: "csdd_nr",
            key: "csdd_nr",
            align: "center",
            render: (group: Student["csdd_nr"], record: Student): React.ReactNode => {
                return group
                    ? <div>
                        <p style={{ margin: 0 }}>{group}</p>
                        <small>{(record.start_date)}</small>
                    </div>
                    : <Button
                        style={{ fontSize: 12, padding: 0, whiteSpace: "normal" }}
                        type="link"
                        onClick={() => setSelectedStudent(record)}
                    >
                        {t('groups.add_to_group')}
                    </Button>
            }
        },
        // {
        //     title: t("common.student"),
        //     dataIndex: "active",
        //     key: "active"
        // },
        {
            title: t("common.category"),
            dataIndex: "category",
            key: "category",
            render: (category: Category) => {
                return <span>{category.value} {t("common.category")}</span>
            }
        },
        {
            title: t("common.language"),
            dataIndex: "language",
            key: "language",
            render: (language: Language) => {
                return <span>{language?.iso?.toUpperCase()}</span>
            }
        },
        {
            title: t("settings.phone"),
            dataIndex: "phone_number",
            key: "phone"
        },
        {
            title: t("settings.email"),
            dataIndex: "email",
            key: "email"
        },
        {
            title: t("common.started_at"),
            dataIndex: "first_login",
            key: "first_login",
            render: (firstLogin: Student['first_login']) => {
                return firstLogin
                    ? firstLogin.split(" ")[0]
                    : "Nav"
            }
        },
        {
            title: t("common.progress"),
            dataIndex: "",
            render: (nothing: any, record: Student) => {
                return <small>{record.elearning_progress?.done_lessons} / {record.elearning_progress?.max_lessons}</small>
            }
        },
        {
            title: "",
            fixed: "right",
            align: "center",
            render: (nothing, record: Student) => {
                return <Link to={`/students/${record.id}`}>
                    <Button>
                        {t("common.open")}
                    </Button>
                </Link>
            }
        },
    ];

    useEffect(() => {
        // firstLoadStudents();
        if (location.state?.create) {
            setOpen(true)
        }
    }, [])

    useEffect(() => {
        if (selectedStudent !== undefined && selectedStudent) {
            setAddStudentToGroupOpened(true);
        } else if (selectedStudent === null) {
            return
        } else {
            setAddStudentToGroupOpened(false);
            getStudents();
        }
    }, [selectedStudent]);

    return <>
        <CreateStudentModal
            open={open}
            setOpen={(val: boolean) => setOpen(val)}
            refreshStudents={getStudents}
            categories={categories}
            languages={languages}
        />
        <AddStudentToGroupModal
            open={addStudentToGroupOpened}
            resetSelectedStudent={() => setSelectedStudent(undefined)}
            student={selectedStudent ?? undefined}
        />
        <Header
            title={title}
            button={<Button
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
                type="primary"
                onClick={() => setOpen(true)}
            >
                <img src={icPlus} style={{ marginRight: 8 }} />
                {t("groups.add_student")}
            </Button>
            }
        />
        <div style={{ padding: 20 }}>
            <Row>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <StudentsFilters
                        categories={categories}
                        languages={languages}
                        initialFilters={studentFilters}
                        getStudents={getStudents}
                    />
                </Col>
                <Table
                    style={{ width: "100%" }}
                    size="small"
                    rowKey={"id"}
                    columns={columns}
                    dataSource={students}
                    scroll={{ x: 1000 }}
                    pagination={{
                        showSizeChanger: false,
                        size: 'small',
                        showTotal: (total) => <div>{t("common.total_students", { count: total })}</div>,
                        position: ['bottomCenter'],
                        pageSizeOptions: ['15'],
                        defaultPageSize: 15,
                        ...pagination
                    }}
                    onChange={handleTableChange}
                    loading={loading}
                />
            </Row>
        </div>
    </>
}