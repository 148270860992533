import { CreateStudyGroupModal } from "./CreateStudyGroupModal";
import { useEffect, useState } from "react";
import { http } from "../../helpers/http";
import { AxiosResponse, AxiosError } from 'axios';
import moment from "moment";
import { PaginationProps } from "antd/lib/pagination";
import { Category, Language } from "../../App";
import { Route, Routes } from "react-router-dom";
import { StudyGroupFilters, StudyGroupsTable } from "./StudyGroupsTable";
import { StudyGroupOpening } from "./StudyGroupOpening";
import { clearFilter, getFilter } from "../../helpers/filtersSaver";

export type StudyGroup = {
    id: number;
    category: Category;
    group_status: GroupStatus | null;
    csdd_nr: string | null;
    start_date: string | null;
    languages: Language[];
    planned_slots?: number;
    free_slots?: number
}

export type GroupStatus = {
    id: number;
    value: string;
}

export type FilterParams = {
    csdd_nr?: string[];
    category?: string[];
    group_status?: string[];

}

type StudyGroupsLayoutProps = {
    title: string;
    categories: Category[];
    languages: Language[];
}

export const StudyGroupsLayout = ({ title, categories, languages }: StudyGroupsLayoutProps) => {
    const [studyGroups, setStudyGroups] = useState<StudyGroup[] | []>([]);
    const [open, setOpen] = useState(false);
    const [statuses, setStatuses] = useState<GroupStatus[] | []>([]);
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    });
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState<StudyGroupFilters>({});

    const getStudyGroups = (page: number = pagination.current ?? 1, reqFilters: StudyGroupFilters = filters) => {
        setFilters(reqFilters)
        setLoading(true);
        http.get(`study-groups`, { params: { page, filters: { ...reqFilters } } }).then((res: AxiosResponse) => {
            const { data, meta } = res.data;
            // if (data.length === 0) return;
            setLoading(false);
            setStudyGroups(() => {
                return data.map((group: StudyGroup) => {
                    return {
                        id: group.id,
                        category: {
                            id: group.category.id,
                            value: group.category.value
                        },
                        csdd_nr: group.csdd_nr,
                        group_status: group.group_status,
                        start_date: moment(group.start_date, "YYYY-MM-DD").format("DD.MM.YYYY"),
                        languages: group.languages,
                        planned_slots: group.planned_slots,
                        free_slots: group.free_slots
                    } as StudyGroup;
                })
            })
            setPagination({
                current: meta.current_page,
                total: meta.total
            })
        }).catch((err: AxiosError) => {
            setLoading(false);
        })
    };

    async function getClassifiers() {
        await http.get('study-group-status-values').then((res: AxiosResponse): void => {
            const { data } = res.data;
            if (data.length === 0) return;
            setStatuses(data.map((item: GroupStatus): GroupStatus => {
                return {
                    id: item.id,
                    value: item.value
                }
            }))
        });
        return;
    }

    const firstLoadStudyGroups = () => {
        // const savedFiltersStr = getFilter();
        // if (savedFiltersStr.length > 0) {
        //     const savedFilter = JSON.parse(savedFiltersStr);
        //     const retrievedFilters = savedFilter.filters as StudyGroupFilters
        //     const retrievedPage = savedFilter.page as number;
        //     setFilters(retrievedFilters);
        //     getStudyGroups({
        //         params: {
        //             page: retrievedPage,
        //             filters: retrievedFilters
        //         }
        //     })
        //     return;
        // }
        getStudyGroups();
    }

    useEffect(() => {
        getClassifiers();
        firstLoadStudyGroups();
    }, []);

    return <>
        <CreateStudyGroupModal
            open={open}
            setOpen={(val: boolean) => setOpen(val)}
            refreshStudyGroups={getStudyGroups}
            languages={languages}
        />
        <Routes>
            <Route
                path="/"
                element={
                    <StudyGroupsTable
                        categories={categories}
                        languages={languages}
                        title={title}
                        studyGroups={studyGroups}
                        getStudyGroups={(page: number, filters?: FilterParams) => getStudyGroups(page, filters)}
                        statuses={statuses}
                        pagination={pagination}
                        setPagination={(pagination: PaginationProps) => setPagination(pagination)}
                        setOpen={(state: boolean) => setOpen(state)}
                        loading={loading}
                        studyGroupFilters={filters}
                    />
                }
            />
            <Route
                path=":id"
                element={
                    <StudyGroupOpening
                        title={title}
                        studyGroups={studyGroups}
                        languages={languages}
                        groupStatuses={statuses}
                        refreshStudyGroups={getStudyGroups}
                    />
                }
            />
        </Routes>
    </>
}