import { Row, Select } from "antd"
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Language } from "../../App";
import { http } from "../../helpers/http";
import { LessonPlayer } from "./LessonPlayer";
import { Chapter } from "./ModulesLayout";

type LessonLayoutProps = {
    cid: string | undefined;
    mid: string | undefined;
    lid: string | undefined;
    iso: string | undefined;
    chapter: Chapter;
    jumpTo: number;
}

export const LessonLayout = ({ cid, mid, chapter, jumpTo, lid, iso }: LessonLayoutProps) => {
    const baseRawUrl = process.env.REACT_APP_API_URL + `/school-api/get-video`
    const [module, setModule] = useState({} as any);
    const [qualities, setQualities] = useState([] as any);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [url, setURL] = useState("");
    const [videoToken, setVideoToken] = useState("");
    const [selectedQuality, setSelectedQuality] = useState("medium");
    const [selectedLanguage, setSelectedLanguage] = useState(Number(lid));

    const checkVideo = async (lang_id: any, quality: any) => {
        const qualityValue = qualities.find((el: string) => el === quality)
        console.log(qualityValue, videoToken)
        if (qualityValue === undefined && videoToken.length === 0) return;
        setURL(`${baseRawUrl}?e_chapter_id=${chapter.hash_id}&language_id=${lang_id}&video_quality=${qualityValue}&bearrer_token=${videoToken}`);
    }

    const getVideoToken = async () => {
        await http.get(`get-video-token`).then((response: any) => {
            setVideoToken(response.data.token.token)
        })
    }

    const getLanguages = () => {
        http.get(`languages`).then((res: AxiosResponse) => {
            console.log(res)
            setLanguages(res.data.data)
        })
    }

    const getQualities = (lang: number) => {
        http.get(`get-video-quality`, {
            params: {
                e_chapter_id: chapter.hash_id,
                language_id: lang
            }
        }).then((res: any) => {
            console.log(res)
            setQualities(res.data.qualities)
        })
    };

    useEffect(() => {
        getLanguages();
        getVideoToken();
    }, []);

    useEffect(() => {
        setSelectedLanguage(Number(lid))
    }, [chapter])

    useEffect(() => {
        checkVideo(selectedLanguage, selectedQuality);
    }, [selectedQuality]);

    useEffect(() => {
        getQualities(selectedLanguage);
        setSelectedQuality("medium");
        setURL("")
    }, [selectedLanguage])

    useEffect(() => {
        if (chapter && chapter !== undefined) {
            if (Object.keys(chapter).length > 0 && videoToken.length > 0) {
                getQualities(selectedLanguage);
            }
        }
    }, [chapter, videoToken]);

    useEffect(() => {
        if (!qualities) return;
        if (qualities.length > 0) {
            checkVideo(selectedLanguage, "medium");
        }
    }, [qualities]);

    return <>
        <Row justify="space-between">
            <h1 style={{ margin: "0 0 20px" }}>{chapter?.ind + 1}. nodaļa - {chapter?.[`title_${iso}` as keyof Chapter]}</h1>
            {/* <div>
                <b>Valoda: </b>
                <Select
                    style={{ width: 100 }}
                    value={selectedLanguage}
                    onChange={(e: number) => {
                        setSelectedLanguage(e)
                    }}
                >
                    {
                        languages.map((lang: Language) => {
                            return <Select.Option key={lang.id} value={lang.id}>{lang.iso?.toUpperCase()}</Select.Option>
                        })
                    }
                </Select>
            </div> */}
        </Row>
        <h3>{chapter?.[`description_${iso}` as keyof Chapter]}</h3>
        <div
            style={{ maxHeight: "90vh" }}
        >
            {
                url.length > 0
                && <LessonPlayer
                    url={url}
                    qualities={qualities}
                    languages={languages}
                    selectedQuality={selectedQuality}
                    changeQuality={setSelectedQuality}
                    jumpTo={jumpTo}
                />
            }
        </div>
    </>
}