import { Button, Col, message, Modal, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Language, Category } from '../../App';
import { http } from '../../helpers/http';
import { Student } from './StudentsLayout';
import { AxiosResponse, AxiosError } from 'axios';
import { StudyGroup, GroupStatus } from '../StudyGroups/StudyGroupsLayout';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type AddStudentToGroupModalProps = {
    open: boolean;
    resetSelectedStudent: () => void;
    student: Student | undefined
}

type GroupResData = {
    id: number;
    csdd_nr: string;
    category_id: number;
    category: string;
    group_status: GroupStatus;
    start_date: string;
    free_slots: number;
    planned_slots: number;
}

export const AddStudentToGroupModal = ({ open, resetSelectedStudent, student }: AddStudentToGroupModalProps) => {
    const { t } = useTranslation();
    const [availableGroups, setAvailableGroups] = useState<StudyGroup[] | []>([]);
    const getGroups = () => {
        http.get(`school-groups-with-free-seats`, {
            params: {
                category: student?.category?.id,
                language: student?.language?.id
            }
        }).then((res: AxiosResponse) => {
            setAvailableGroups(
                res.data.data.map((group: GroupResData): StudyGroup => {
                    return {
                        id: group?.id,
                        csdd_nr: group?.csdd_nr,
                        category: {
                            id: group?.category_id,
                            value: group?.category,
                        },
                        languages: [],
                        group_status: group?.group_status,
                        start_date: moment(group?.start_date, "YYYY-MM-DD").format("DD.MM.YYYY"),
                        free_slots: group?.free_slots,
                        planned_slots: group?.planned_slots
                    }
                })
            )
        })
    };

    const addStudentToGroup = (group_id: number) => {
        http.post(`add-student-to-group/${student?.id}/${group_id}`).then((res: AxiosResponse) => {
            resetSelectedStudent();
        }).catch((err: AxiosError) => {
            message.error(t("errors.error"))
        })
    };

    const columns: ColumnsType<StudyGroup> = [
        {
            title: t("common.category"),
            dataIndex: "category",
            render: (category: Category): React.ReactNode => {
                return category.value + " " + t("common.category")
            }
        },
        {
            title: t("groups.csdd_group"),
            dataIndex: "csdd_nr"
        },
        {
            title: t("common.students"),
            render: (nothing: any, record: StudyGroup): React.ReactNode => {
                return `${(record?.planned_slots ?? 0) - (record?.free_slots ?? 0)} / ${record?.planned_slots}`
            }
        },
        {
            title: t("common.beginning"),
            dataIndex: "start_date"
        },
        {
            title: "",
            dataIndex: "id",
            render: (id: StudyGroup["id"]): React.ReactNode => {
                return <Button
                    style={{ fontSize: 12 }}
                    type="link"
                    onClick={() => addStudentToGroup(id)}
                >
                    {t("groups.add_to_group")}
                </Button>
            }
        },
    ]

    useEffect(() => {
        if (open) {
            getGroups();
        }
    }, [open]);

    return <Modal
        open={open}
        onCancel={resetSelectedStudent}
        width={830}
    >
        <div style={{ padding: 20 }}>
            <Row justify="space-evenly" gutter={[30, 10]} style={{ paddingBottom: 20 }}>

                <Col>
                    <div>
                        <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("common.student")}:</p>
                        <b style={{ fontSize: 14, lineHeight: "22px" }}>{student?.first_name} {student?.last_name}</b>
                    </div>
                </Col>
                <Col>
                    <div>
                        <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("common.personal_id")}:</p>
                        <b style={{ fontSize: 14, lineHeight: "22px" }}>{student?.personal_identity_number}</b>
                    </div>
                </Col>
                <Col>
                    <div>
                        <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("common.category")}:</p>
                        <b style={{ fontSize: 14, lineHeight: "22px" }}>{student?.category?.value} kategorija</b>
                    </div>
                </Col>
                <Col>
                    <div>
                        <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("common.language")}:</p>
                        <b style={{ fontSize: 14, lineHeight: "22px" }}>{student?.language?.iso?.toUpperCase()}</b>
                    </div>
                </Col>
                <Col>
                    <div>
                        <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("settings.phone")}:</p>
                        <b style={{ fontSize: 14, lineHeight: "22px" }}>{student?.phone_number}</b>
                    </div>
                </Col>
                <Col>
                    <div>
                        <p style={{ margin: 0, marginBottom: 2, fontSize: 11 }}>{t("settings.email")}:</p>
                        <b style={{ fontSize: 14, lineHeight: "22px" }}>{student?.email}</b>
                    </div>
                </Col>
            </Row>
            <div
                style={{
                    borderTop: "1px solid #E4E7EB",
                    paddingTop: 20
                }}
            >
                <Row justify="space-between" style={{ marginBottom: 20 }}>
                    <Col>
                        <p style={{ margin: 0, marginBottom: 2, fontSize: 14 }}>{t("groups.available_groups")}:</p>
                    </Col>
                    {/* <Col>
                        <Button
                            type="link"
                            style={{ display: "flex", alignItems: "center", height: 38 }}
                        >
                            {t("groups.create_new_group")}
                        </Button>
                    </Col> */}
                </Row>
                <Table
                    size='small'
                    rowKey={"id"}
                    columns={columns}
                    dataSource={availableGroups}
                    scroll={{ x: 600 }}
                />
            </div>
        </div>
    </Modal>
}