import { Button, Row, Col, Select, Input, Table, Tag } from "antd";
import pagination, { PaginationProps } from "antd/es/pagination";
import { ColumnsType } from "antd/es/table";
import { title } from "process";
import { useEffect, useState } from "react";
import { Category, Language } from "../../App";
import { Header } from "../components/Header";
import { FilterParams, GroupStatus, StudyGroup } from "./StudyGroupsLayout";
import icPlus from "../../img/ic-plus.svg";
import { Link, useLocation } from "react-router-dom";
import { clearFilter, getFilter, saveFilter } from "../../helpers/filtersSaver";
import { StudyGroupsFilters } from "./StudyGroupsFilters";
import { useTranslation } from 'react-i18next';

type StudyGroupsTableProps = {
    title: string,
    categories: Category[];
    languages: Language[];
    studyGroups: StudyGroup[];
    getStudyGroups: (page: number, filter?: FilterParams) => void;
    statuses: GroupStatus[];
    pagination: PaginationProps;
    setPagination: (pagination: PaginationProps) => void;
    setOpen: (state: boolean) => void;
    loading: boolean;
    studyGroupFilters: StudyGroupFilters;
}

export type StudyGroupFilters = {
    csdd_nr?: string[];
    category?: string[];
    group_status?: string[];
}

export const StudyGroupsTable = ({
    title,
    categories,
    getStudyGroups,
    pagination,
    studyGroupFilters,
    statuses,
    studyGroups,
    setOpen,
    loading }: StudyGroupsTableProps) => {
    const { t } = useTranslation()
    const location = useLocation();

    const handleTableChange = (pagination: PaginationProps) => {
        getStudyGroups(pagination.current ?? 1)
    };

    useEffect(() => {
        if (location.state?.create) {
            setOpen(true)
        }
    }, []);

    const columns: ColumnsType<StudyGroup> = [
        {
            title: t("groups.csdd_group"),
            dataIndex: "csdd_nr",
            key: "csdd_nr",
            fixed: "left"
        },
        {
            title: t("common.category"),
            dataIndex: "category",
            key: "category",
            render: (category: Category) => {
                return <span>{category.value} {t("common.category")}</span>
            }
        },
        {
            title: t("common.language"),
            dataIndex: "languages",
            key: "languages",
            render: (languages: Language[]) => {
                if (languages.length === 0) return <></>
                return <span>{languages[0].iso?.toUpperCase()}</span>
            }
        },
        // {
        //     title: t("groups.group_status"),
        //     dataIndex: "group_status",
        //     key: "group_status",
        //     render: (status: StudyGroup['group_status']) => {
        //         return status ?
        //             <Tag>{status.value}</Tag>
        //             : <></>
        //     }
        // },
        {
            title: t("common.students"),
            render: (nothing: any, record: StudyGroup): React.ReactNode => {
                return `${(record.planned_slots ?? 0) - (record.free_slots ?? 0)}/${record.planned_slots ?? 0}`
            }
        },
        {
            title: t("common.start"),
            dataIndex: "start_date",
            key: "start_date"
        },
        {
            title: "",
            fixed: "right",
            align: "center",
            render: (nothing, record: StudyGroup) => {
                return <Link to={`/study-groups/${record.id}`}>
                    <Button>
                        {t("common.open")}
                    </Button>
                </Link>
            }
        },
    ];

    return <>
        <Header
            title={title}
            counter={pagination.total}
            button={<Button
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
                type="primary"
                onClick={() => setOpen(true)}
            >
                <img src={icPlus} style={{ marginRight: 8 }} />
                {t("groups.create_new_group")}
            </Button>
            }
        />
        <div style={{ padding: 20 }}>
            <Row>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <StudyGroupsFilters
                        categories={categories}
                        statuses={statuses}
                        getStudyGroups={(page: number, filter?: FilterParams) => getStudyGroups(page, filter)}
                        initialFilters={studyGroupFilters}
                    />
                </Col>
                <Table
                    style={{ width: "100%" }}
                    size="small"
                    rowKey={"id"}
                    columns={columns}
                    dataSource={studyGroups}
                    scroll={{ x: 1000 }}
                    loading={loading}
                    pagination={{
                        showSizeChanger: false,
                        size: 'small',
                        showTotal: (total) => <div>{t("common.total_groups", { count: total })}</div>,
                        position: ['bottomCenter'],
                        pageSizeOptions: ['15'],
                        defaultPageSize: 15,
                        ...pagination
                    }}
                    onChange={handleTableChange}
                />
            </Row>
        </div>
    </>
}