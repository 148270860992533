import { Row, Col, Select, Input, Button } from "antd";
import { useState } from "react";
import { Category } from "../../App";
import { FilterParams, GroupStatus } from "./StudyGroupsLayout";
import { StudyGroupFilters } from "./StudyGroupsTable";
import { useTranslation } from 'react-i18next';

type StudyGroupsFiltersProps = {
    categories: Category[];
    statuses: GroupStatus[];
    getStudyGroups: (page: number, filter?: FilterParams) => void;
    initialFilters: StudyGroupFilters;
}

export const StudyGroupsFilters = ({ categories, statuses, getStudyGroups, initialFilters }: StudyGroupsFiltersProps) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<StudyGroupFilters>(initialFilters);

    const handleFiltersBar = () => {
        getStudyGroups(1, { ...filters })
    };

    const resetFilters = () => {
        setFilters({})
        getStudyGroups(1, {})
    };

    return <Row className="shadow-card" style={{ padding: 20 }} align="bottom" justify={"space-between"}>
        <Row gutter={10}>
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.category")}</p>
                <Select
                    style={{ width: 140 }}
                    value={Number(filters.category?.[0]) || null}
                    onChange={(e: number) => {
                        setFilters((prev: StudyGroupFilters | undefined): StudyGroupFilters => {
                            if (e === 0) {
                                const copyPrev: StudyGroupFilters = JSON.parse(JSON.stringify(prev));
                                delete copyPrev.category;
                                return copyPrev;
                            }
                            return {
                                ...prev,
                                category: [String(e)]
                            }
                        })
                    }}
                >
                    {filters?.hasOwnProperty("category") && <Select.Option value={0}> </Select.Option>}
                    {
                        categories.map((category: Category) => {
                            return <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>
                        })
                    }
                </Select>
            </Col>
            {/* <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.status")}</p>
                <Select
                    style={{ width: 140 }}
                    value={Number(filters.group_status?.[0]) || null}
                    onChange={(e: number) => {
                        setFilters((prev: StudyGroupFilters | undefined): StudyGroupFilters => {
                            if (e === 0) {
                                const copyPrev: StudyGroupFilters = JSON.parse(JSON.stringify(prev));
                                delete copyPrev.group_status;
                                return copyPrev;
                            }
                            return {
                                ...prev,
                                group_status: [String(e)]
                            }
                        })
                    }}
                >
                    {filters?.hasOwnProperty("group_status") && <Select.Option value={0}> </Select.Option>}
                    {
                        statuses.map((status: GroupStatus) => {
                            return <Select.Option key={status.id} value={status.id}>{status.value}</Select.Option>
                        })
                    }
                </Select>
            </Col> */}
            <Col>
                <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("groups.csdd_group")}</p>
                <Input
                    style={{ width: 140 }}
                    value={filters.csdd_nr?.[0]}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFilters((prev: StudyGroupFilters | undefined): StudyGroupFilters => {
                            if (event.target.value.length === 0) {
                                const copyPrev: StudyGroupFilters = JSON.parse(JSON.stringify(prev));
                                delete copyPrev.csdd_nr;
                                return copyPrev;
                            }
                            return {
                                ...prev,
                                csdd_nr: [event.target.value]
                            }
                        })
                    }}
                />
            </Col>
        </Row>
        <div>
            {
                Object.keys(filters).length > 0
                && <Button
                    style={{ marginRight: 10 }}
                    onClick={resetFilters}
                >
                    {t("filters.erase_filters")}
                </Button>
            }
            <Button
                style={{ backgroundColor: "black" }}
                type="primary"
                onClick={handleFiltersBar}
            >
                {t("filters.search")}
            </Button>
        </div>
    </Row>
}