import { Button, Col, Input, message, Modal, Row } from "antd"
import dayjs from "dayjs";
import { Message } from "../Messages/MessagesLayout"
import icArrowDark from "../../img/ic-goback.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { http } from "../../helpers/http";
import { useTranslation } from "react-i18next";

type ReplyCardProps = {
    messageObj: Message;
}

export const ReplyCard = ({ messageObj }: ReplyCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [answer, setAnswer] = useState("");

    const replyToAMessage = () => {
        Modal.confirm({
            title: t("e_learning.answer_student") + ": " + messageObj.student,
            content: <div>
                <p><b>{t("e_learning.question").charAt(0).toUpperCase() + t("e_learning.question").slice(1)}: </b>{messageObj.question}</p>
                <p><b>{t("e_learning.answer")}: </b>{answer}</p>
            </div>,
            onOk: () => {
                http.post(`answer-student-question/${messageObj.id}`,
                    {
                        answer
                    }
                ).then(() => {
                    message.success(t("e_learning.answer_sent"))
                }).catch(() => {
                    message.error(t("errors.error"))
                })
            }
        })
    }

    return <Row>
        <Col span={24}>
            <h3>{t("e_learning.student_question", { student: messageObj.student })}:</h3>
            <p>“{messageObj.question}”</p>
            <b>{t("e_learning.time")}: {dayjs(String(messageObj.seconds), "s").format("HH:mm:ss")}</b>
        </Col>
        <Col
            span={24}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                marginTop: 15
            }}
        >
            <Input.TextArea
                placeholder={t("e_learning.answer_input") as string}
                onChange={(e => {
                    setAnswer(e.target.value)
                })}
            />
        </Col>
        <Col span={24}>
            <Row justify={"space-between"}>
                <Button
                    type="text"
                    style={{
                        marginTop: 15,
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center"
                    }}
                    onClick={() => navigate(-1)}
                >
                    <img src={icArrowDark} style={{ marginRight: 8 }} />
                    {t("e_learning.return_to_questions")}
                </Button>
                <Button
                    type="primary"
                    style={{
                        width: "150px",
                        marginTop: 15
                    }}
                    onClick={replyToAMessage}
                >
                    {t("e_learning.answer_to_email")}
                </Button>
            </Row>
        </Col>
    </Row>
}