import { Button, Col, Row } from "antd"
import { Route, Routes, useNavigate } from "react-router-dom";
import { Header } from "../components/Header"
import { CategoryButton } from "./CategoryButton"
import "./LearningModules.scss";
import { ModulesLayout } from "./ModulesLayout";
import { ChapterLayout } from './ChapterLayout';
import { useEffect, useState } from "react";
import { Category } from "../../App";
import { http } from "../../helpers/http";
import { AxiosResponse } from 'axios';
import icGoBack from '../../img/ic-goback.svg';
import { LanguagesLayout } from "./LanguagesLayout";

type LearningModulesLayoutProps = {
    title: string
}

export const LearningModulesLayout = ({ title }: LearningModulesLayoutProps) => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState<Category[] | []>([]);

    const getCategories = () => {
        http.get(`categories`).then((res: AxiosResponse) => {
            console.log(res)
            setCategories(res.data.data.map((item: Category): Category => {
                return {
                    id: item.id,
                    value: item.value
                }
            }))
        })
    }

    useEffect(() => {
        getCategories();
    }, []);

    return <div className="learning-modules-layout">
        <Header
            title={<>
                <Button
                    type="text"
                    style={{ padding: 0, marginRight: 8, height: "auto", display: "flex", alignItems: "center" }}
                    onClick={() => navigate(-1)}
                >
                    <img src={icGoBack} />
                </Button>
                {title}
            </>}
        />
        <Routes>
            <Route
                path="/"
                element={<Row gutter={[20, 20]} style={{ padding: 20 }}>
                    {
                        categories.map((category: Category) => {
                            return <Col md={8} lg={6} span={24} key={category.id}>
                                <CategoryButton
                                    title={category.value ?? ""}
                                    id={category.id ?? 0}
                                />
                            </Col>
                        })
                    }
                </Row>}
            />
            <Route
                path=":catid"
                element={<ModulesLayout
                    categories={categories}
                />}
            />
            <Route
                path=":catid/:mid/:cid"
                element={<LanguagesLayout />}
            />
            <Route
                path=":catid/:mid/:cid/:lid/:iso"
                element={<ChapterLayout />}
            />
        </Routes>

    </div>
}