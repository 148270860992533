
interface BigMainButtonProps {
    title: string,
    icon: string
}

export const BigMainButton = ({ title, icon }: BigMainButtonProps) => {

    return <button
        style={{
            height: 160,
            width: "100%",
            boxShadow: "0 3px 6px 0 rgba(64,96,128,0.1)",
            backgroundColor: "#FFFFFF",
            borderRadius: 4,
            border: "1px solid #CED4D9",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
            cursor: "pointer"
        }}
    >
        <div style={{ marginBottom: 17 }}>
            <img src={icon} />
        </div>
        <span style={{ fontSize: 20, color: "#303136", textAlign: "center" }}>
            {title}
        </span>
    </button>
}