import { Button, Card, Col, Input, message, Modal, Row, Select, Tag } from 'antd';
import { Link, useNavigate, useParams } from "react-router-dom"
import { Header } from "../components/Header";
import icGoBack from "../../img/ic-goback.svg";
import { useEffect, useState } from "react";
import { Student, StudentGroupStatus } from "./StudentsLayout";
import { Language, Category } from '../../App';
import { http } from "../../helpers/http";
import { AxiosResponse, AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

type StudentOpeningProps = {
    title: string;
    students: Student[];
    languages: Language[];
    categories: Category[];
    refreshStudents: () => void;
}

type EditStudentParams = {
    first_name: string | null;
    last_name: string | null;
    personal_identity_number: string | null;
    phone: string | null;
    email: string | null;
    language_id: number | null;
    category_id: number | null;
}

export const StudentOpening = ({ title, students, languages, categories, refreshStudents }: StudentOpeningProps) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [student, setStudent] = useState<Student>();
    const [editStudentParams, setEditStudentParams] = useState<EditStudentParams>({
        first_name: null,
        last_name: null,
        personal_identity_number: null,
        phone: null,
        email: null,
        language_id: null,
        category_id: null
    });
    const [loading, setLoading] = useState(false);
    const [studentGroupStatuses, setStudentGroupStatuses] = useState<StudentGroupStatus[]>([]);
    const [reservationType, setReservationType] = useState("interested");

    const getStudent = async () => {
        // const student = students.find((student: Student) => { return Number(student.id) === Number(id) });
        const student = await http.get(`student/${id}`).then((res: AxiosResponse): Student | undefined => {
            console.log(res)
            if (res.data.data) {
                return {
                    id: res.data.data[0].id,
                    first_name: res.data.data[0].first_name,
                    last_name: res.data.data[0].last_name,
                    email: res.data.data[0].email,
                    personal_identity_number: res.data.data[0].personal_identity_number,
                    phone_number: res.data.data[0].phone_number,
                    category: {
                        id: res.data.data[0].category.id,
                        value: res.data.data[0].category.value,
                    },
                    reservation_type: res.data.data[0].reservation_type,
                    study_group: res.data.data[0].study_group,
                    csdd_nr: res.data.data[0].csdd_nr,
                    sent_spams: res.data.data[0].sent_spams,
                    language: {
                        id: res.data.data[0].language.id,
                        iso: res.data.data[0].language.iso,
                    }
                }
            }
            return undefined;
        })
        if (student !== undefined) {
            setEditStudentParams({
                first_name: student.first_name,
                last_name: student.last_name,
                email: student.email,
                phone: student.phone_number,
                personal_identity_number: student.personal_identity_number,
                language_id: student.language?.id === undefined ? null : student.language?.id,
                category_id: student.category?.id === undefined ? null : student.category?.id,
            })
            setStudent(student)
            console.log(student.reservation_type)
            setReservationType(student.reservation_type ?? "interested")
        }

    };

    const updateStudent = () => {
        http.post(`change-student/${id}`, { ...editStudentParams }).then((res: AxiosResponse) => {
            message.success(t("notification.student_changes_saved_successfuly"));
            refreshStudents();
            getStudent();
        }).catch((err: AxiosError) => {
            message.error(t("errors.error"));
        })
    };

    const getStudentStatuses = () => {
        http.get(`school-groups-student-statuses`).then((res: AxiosResponse) => {
            setStudentGroupStatuses(res.data.data)
        })
    }

    const sendStudentSpam = () => {
        http.post(`send-student-crendentials/${student?.study_group}/${student?.id}`).then((res: AxiosResponse) => {
            message.success(t("notification.sent"));
            getStudent();
            refreshStudents();
        }).catch((err: AxiosError) => {
            message.error(t("errors.error"));
        })
    };

    const removeFromGroup = () => {
        Modal.confirm({
            title: t("groups.delete_student_from_group"),
            content: <b>{t("notification.you_sure_want_remove_student_from_group", { first_name: student?.first_name, last_name: student?.last_name, csdd_nr: student?.csdd_nr })}</b>,
            onOk: () => {
                // changeStudentReservation();
                http.post(`delete-student-from-group/${student?.id}/${student?.study_group}`).then((res: AxiosResponse) => {
                    getStudent();
                    refreshStudents();
                }).catch(() => {
                    message.error(t("errors.error"))
                })
            }
        })
    }

    const changeStudentReservation = () => {
        http.post(`change-study-reservation/${student?.id}/${student?.study_group}`, { reservation_type: "interested" }).then((res: AxiosResponse) => {
            // message.success("Studenta status grupā ir nomainīts!")
            // refreshStudents();
            // getStudent();
        }).catch(() => {
            message.error(t("errors.error"))
        })
    }

    useEffect(() => {
        getStudentStatuses();
        getStudent();
    }, []);

    return <div>
        <Header
            title={title}
        />
        <div style={{ padding: 20 }}>
            <Row>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <Row
                        className="shadow-card"
                        style={{ padding: 20 }}
                        justify={"space-between"}
                        gutter={[20, 20]}
                    >
                        <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                            <Button
                                onClick={() => navigate(-1)}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 38,
                                    height: 38,
                                    borderRadius: 4,
                                    backgroundColor: "#F0F2F5",
                                    marginRight: 20
                                }}
                            >
                                <img src={icGoBack} />
                            </Button>
                            <h3 style={{ margin: 0 }}><b>{t("students.student_info_editing", { first_name: student?.first_name, last_name: student?.last_name })}</b></h3>
                        </Col>
                        <Col md={12} span={24}>
                            <Card
                                loading={loading}
                            >
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <h2 style={{ marginTop: 0 }}><b>{t("common.general_info")}</b></h2>
                                    </Col>
                                    <Col>
                                        <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("settings.name")}</b></p>
                                        <Input
                                            style={{ width: 200, marginBottom: 10 }}
                                            value={editStudentParams.first_name ?? undefined}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setEditStudentParams((prev: EditStudentParams): EditStudentParams => {
                                                    return {
                                                        ...prev,
                                                        first_name: e.target.value.length > 0 ? e.target.value : null
                                                    }
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("settings.surname")}</b></p>
                                        <Input
                                            style={{ width: 200, marginBottom: 10 }}
                                            value={editStudentParams.last_name ?? undefined}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setEditStudentParams((prev: EditStudentParams): EditStudentParams => {
                                                    return {
                                                        ...prev,
                                                        last_name: e.target.value.length > 0 ? e.target.value : null
                                                    }
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("common.personal_id")}</b></p>
                                        <Input
                                            style={{ width: 200, marginBottom: 10 }}
                                            value={editStudentParams.personal_identity_number ?? undefined}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setEditStudentParams((prev: EditStudentParams): EditStudentParams => {
                                                    return {
                                                        ...prev,
                                                        personal_identity_number: e.target.value.length > 0 ? e.target.value : null
                                                    }
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("settings.phone")}</b></p>
                                        <Input
                                            style={{ width: 200, marginBottom: 10 }}
                                            value={editStudentParams.phone ?? undefined}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setEditStudentParams((prev: EditStudentParams): EditStudentParams => {
                                                    return {
                                                        ...prev,
                                                        phone: e.target.value.length > 0 ? e.target.value : null
                                                    }
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("settings.email")}</b></p>
                                        <Input
                                            style={{ width: 200, marginBottom: 10 }}
                                            value={editStudentParams.email ?? undefined}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setEditStudentParams((prev: EditStudentParams): EditStudentParams => {
                                                    return {
                                                        ...prev,
                                                        email: e.target.value.length > 0 ? e.target.value : null
                                                    }
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("common.language")}</b></p>
                                        <Select
                                            style={{ width: 200, marginBottom: 10 }}
                                            value={editStudentParams.language_id}
                                            onChange={(e: number) => {
                                                setEditStudentParams((prev: EditStudentParams): EditStudentParams => {
                                                    return {
                                                        ...prev,
                                                        language_id: e > 0 ? e : null
                                                    }
                                                })
                                            }}
                                        >
                                            {editStudentParams.language_id && <Select.Option value={-1}> </Select.Option>}
                                            {
                                                languages.map((language: Language): React.ReactNode => {
                                                    return <Select.Option value={language.id}>{language.iso?.toUpperCase()}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Col>
                                    {
                                        student?.study_group === null
                                        && <Col>
                                            <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("common.category")}</b></p>
                                            <Select
                                                style={{ width: 200, marginBottom: 10 }}
                                                value={editStudentParams.category_id}
                                                onChange={(e: number) => {
                                                    setEditStudentParams((prev: EditStudentParams): EditStudentParams => {
                                                        return {
                                                            ...prev,
                                                            category_id: e > 0 ? e : null
                                                        }
                                                    })
                                                }}
                                            >
                                                {editStudentParams.category_id && <Select.Option value={-1}> </Select.Option>}
                                                {
                                                    categories.map((category: Category): React.ReactNode => {
                                                        return <Select.Option value={category.id}>{category.value}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Col>
                                    }
                                </Row>
                                <Row justify={"end"}>
                                    <Button
                                        type="primary"
                                        disabled={
                                            (
                                                editStudentParams.first_name === null
                                                || editStudentParams.last_name === null
                                                || editStudentParams.personal_identity_number === null
                                                || editStudentParams.phone === null
                                                || editStudentParams.email === null
                                                || editStudentParams.language_id === null
                                                || editStudentParams.category_id === null
                                            ) || (
                                                editStudentParams.first_name === student?.first_name
                                                && editStudentParams.last_name === student?.last_name
                                                && editStudentParams.personal_identity_number === student?.personal_identity_number
                                                && editStudentParams.phone === student?.phone_number
                                                && editStudentParams.email === student?.email
                                                && editStudentParams.language_id === student?.language?.id
                                                && editStudentParams.category_id === student?.category?.id
                                            )
                                        }
                                        onClick={updateStudent}
                                    >
                                        {t("common.save")}
                                    </Button>
                                </Row>
                            </Card>
                        </Col>
                        {
                            student?.study_group
                            && <Col md={12} span={24}>
                                <Card>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <h2 style={{ marginTop: 0 }}><b>{t("groups.group_info")}</b></h2>
                                        </Col>
                                        <Col>
                                            <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("common.category")}</b></p>
                                            <h3 style={{ marginTop: 0 }}><b>{student?.category?.value}</b></h3>
                                        </Col>
                                        <Col>
                                            <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("groups.group")}</b></p>
                                            <h3 style={{ marginTop: 0 }}><b>{student?.csdd_nr}</b></h3>
                                        </Col>
                                        {/* <Col>
                                            <p style={{ marginBottom: 6, marginTop: 0 }}><b>Status</b></p>
                                            <Select
                                                value={reservationType}
                                                style={{ width: 120 }}
                                                onChange={(e) => {
                                                    setReservationType(e)
                                                }}
                                            >
                                                {
                                                    studentGroupStatuses?.map((status: StudentGroupStatus): React.ReactNode => {
                                                        return <Select.Option key={status} value={status}>{status}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                            <Button
                                                onClick={() => changeStudentReservation()}
                                            >
                                                Saglabāt
                                            </Button>
                                        </Col> */}
                                        <Col>
                                            <p style={{ marginBottom: 6, marginTop: 0 }}><b>{t("notification.access_information_sent")}</b></p>
                                            <h3 style={{ marginTop: 0 }}><b>
                                                {
                                                    student?.sent_spams
                                                        ? student?.sent_spams !== undefined
                                                            ? student?.sent_spams[0].sent !== null
                                                                ? <>
                                                                    <Tag color="green">{t("notification.received")}</Tag>
                                                                    <Button
                                                                        onClick={sendStudentSpam}
                                                                    >
                                                                        {t("notification.send_again")}
                                                                    </Button>
                                                                </>
                                                                : <>
                                                                    <Tag color="red">{t("notification.not_received")}</Tag>
                                                                    <Button
                                                                        onClick={sendStudentSpam}
                                                                    >
                                                                        {t("common.send")}
                                                                    </Button>
                                                                </>
                                                            : <></>
                                                        : <></>
                                                }
                                            </b></h3>
                                        </Col>
                                    </Row>
                                    <Row justify={"end"}>
                                        <Button
                                            danger
                                            type='primary'
                                            onClick={() => removeFromGroup()}
                                        >
                                            {t("groups.delete_student_from_group")}
                                        </Button>
                                    </Row>
                                </Card>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
        </div>
    </div>
}