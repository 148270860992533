import { Button, Col, message, Modal, Row } from "antd";
import { User } from "../../App";
import { http } from "../../helpers/http";
import icUser from "../../img/ic-sidebar-data-active.svg";
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

type UserCardProps = {
    user: User;
    setSelectedUser: (user: User) => void;
}

export const UserCard = ({ user, setSelectedUser }: UserCardProps) => {
    const { t } = useTranslation();
    const sendAccessLink = () => {
        Modal.confirm({
            title: t("notification.send_access_link"),
            content: t("notification.if_confirm_link_will_be_sent"),
            onOk: () => {
                http.get(`send-new-password/${user?.id}`).then((res: AxiosResponse) => {
                    message.success(t("notification.access_information_sent"))
                }).catch(() => {
                    message.error(t("errors.error"));
                })
            }
        })
    }

    return <Row
        className="shadow-card"
        style={{ padding: 11, marginBottom: 4 }}
        align="middle"
        justify={"space-between"}
        gutter={[20, 10]}
    >
        <Col md={6} span={24}>
            <Row>
                <img style={{ marginRight: 10 }} src={icUser} alt="" />
                <b style={{ wordBreak: "break-all" }}>{user?.first_name} {user?.last_name}</b>
            </Row>
        </Col>
        <Col md={6} span={24}>
            <b style={{ wordBreak: "break-all" }}>{!!user?.ruler_of_universe ? t("users.admin") : t("users.user")}</b>
        </Col>
        <Col md={4} span={24}>
            <b style={{ wordBreak: "break-all" }}>{user?.phone}</b>
        </Col>
        <Col md={4} span={24}>
            <b style={{ wordBreak: "break-all" }}>{user?.email}</b>
        </Col>
        <Col md={4} span={24}>
            <Button
                onClick={() => setSelectedUser(user)}
                style={{ marginRight: 8 }}
            >
                {t("common.edit")}
            </Button>
            <Button
                onClick={sendAccessLink}
            >
                {t("notification.send_access")}
            </Button>
        </Col>
    </Row>
}