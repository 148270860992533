import { Modal, Button, Row, Col, Input, Select, DatePicker, message } from "antd"
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment"
import { SetStateAction, useEffect, useState } from "react";
import { Category, Language } from "../../App";
import { http } from "../../helpers/http";
import icAddStudent from "../../img/ic-add-student.svg";
import { useTranslation } from 'react-i18next';

type CreateStudentModalProps = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refreshStudents: () => void;
    categories: Category[];
    languages: Language[];
};

export type StudentTemplate = {
    first_name: string | null;
    last_name: string | null;
    personal_identity_number: string | null;
    email: string | null;
    phone: string | null;
    category_id: number | null;
    language_id: number | null;
}

export const CreateStudentModal = ({ open, setOpen, refreshStudents, categories, languages }: CreateStudentModalProps) => {
    const { t } = useTranslation();
    const [newStudent, setNewStudent] = useState<StudentTemplate>({
        first_name: null,
        last_name: null,
        personal_identity_number: null,
        email: null,
        phone: null,
        category_id: null,
        language_id: null
    });
    const [loading, setLoading] = useState(false);

    const addStudent = () => {
        setLoading(true);
        http.post(`add-student`, { ...newStudent }).then((res: AxiosResponse) => {
            setLoading(false);
            resetModal();
            refreshStudents();
        }).catch((err: AxiosError) => {
            setLoading(false);
            message.error(t("errors.error"))
        })
    }

    const resetModal = () => {
        setOpen(false);
        setNewStudent({
            first_name: null,
            last_name: null,
            personal_identity_number: null,
            email: null,
            phone: null,
            category_id: null,
            language_id: null
        })
    };

    return <Modal
        open={open}
        onCancel={() => resetModal()}
        destroyOnClose={true}
        footer={<Button
            type="primary"
            style={{ width: "100%" }}
            onClick={addStudent}
            disabled={
                !newStudent.first_name
                || !newStudent.last_name
                || !newStudent.personal_identity_number
                || !newStudent.phone
                || !newStudent.email
                || !newStudent.category_id
                || !newStudent.language_id
            }
        >
            {t("groups.add_student")}
        </Button>}
        width={320}
    >
        <Row>
            <Col span={24} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <img src={icAddStudent} />
                </div>
                <span style={{ fontSize: 20, color: "#303136", textAlign: "center" }}>
                    {t("groups.add_new_student")}
                </span>
            </Col>
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("settings.name")}</p>
            <Input
                style={{ marginBottom: 20 }}
                type="first_name"
                onChange={(e) => {
                    setNewStudent((prev: StudentTemplate): StudentTemplate => {
                        return {
                            ...prev,
                            first_name: e.target.value.length > 0 ? e.target.value : null
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("settings.surname")}</p>
            <Input
                style={{ marginBottom: 20 }}
                type="last_name"
                onChange={(e) => {
                    setNewStudent((prev: StudentTemplate): StudentTemplate => {
                        return {
                            ...prev,
                            last_name: e.target.value.length > 0 ? e.target.value : null
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.personal_id")}</p>
            <Input
                style={{ marginBottom: 20 }}
                type="personal_identity_number"
                onChange={(e) => {
                    setNewStudent((prev: StudentTemplate): StudentTemplate => {
                        return {
                            ...prev,
                            personal_identity_number: e.target.value.length > 0 ? e.target.value : null
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("settings.phone")}</p>
            <Input
                style={{ marginBottom: 20 }}
                type="phone"
                onChange={(e) => {
                    setNewStudent((prev: StudentTemplate): StudentTemplate => {
                        return {
                            ...prev,
                            phone: e.target.value.length > 0 ? e.target.value : null
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("settings.email")}</p>
            <Input
                style={{ marginBottom: 20 }}
                type="email"
                onChange={(e) => {
                    setNewStudent((prev: StudentTemplate): StudentTemplate => {
                        return {
                            ...prev,
                            email: e.target.value.length > 0 ? e.target.value : null
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.category")}</p>
            <Select
                style={{ width: "100%", marginBottom: 20 }}
                onChange={e => {
                    setNewStudent((prev: StudentTemplate): StudentTemplate => {
                        return {
                            ...prev,
                            category_id: e
                        }
                    })
                }}
            >
                {
                    categories.map((category: Category) => {
                        return <Select.Option key={category.id} value={category.id}>{category.value}</Select.Option>
                    })
                }
            </Select>
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.language")}</p>
            <Select
                style={{ width: "100%", marginBottom: 20 }}
                onChange={e => {
                    setNewStudent((prev: StudentTemplate): StudentTemplate => {
                        return {
                            ...prev,
                            language_id: e
                        }
                    })
                }}
            >
                {
                    languages.map((language: Language) => {
                        return <Select.Option key={language.id} value={language.id}>{language.iso?.toUpperCase()}</Select.Option>
                    })
                }
            </Select>
        </Row>
    </Modal>
}