import { Collapse } from "antd"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Category } from "../../App";
import { http } from "../../helpers/http";
import { ChapterTitleCard } from './ChapterTitleCard';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

type ModulesLayoutProps = {
    categories: Category[]
}

export type Module = {
    id: number;
    description_lv: string;
    description_en: string;
    description_ru: string;
    title_lv: string;
    title_en: string;
    title_ru: string;
    hash_id: string;
    chapters: Chapter[];
}

export type Chapter = {
    id: number;
    description_lv: string;
    description_en: string;
    description_ru: string;
    title_lv: string;
    title_en: string;
    title_ru: string;
    hash_id: string;
    ind: number;
}

export const ModulesLayout = ({ categories }: ModulesLayoutProps) => {
    const { t } = useTranslation();
    const { catid } = useParams();
    const [category, setCategory] = useState<Category | null | undefined>(null);
    const [modules, setModules] = useState<Module[]>([]);

    const getModules = () => {
        http.get(`learning-modules-list/${catid}`).then((res: AxiosResponse) => {
            console.log(res)
            setModules(res.data)
        })
    }

    useEffect(() => {
        if (!category) {
            setCategory(categories.find((category: Category) => category.id === Number(catid)))
        }
    }, [categories]);

    useEffect(() => {
        if (category) {
            getModules();
        }
    }, [category])

    return <div style={{ padding: 20 }}>
        <div
            style={{ padding: 40 }}
            className="shadow-card"
        >
            <h1 style={{ margin: "0 0 20px" }}>{category?.value} {t("e_learning.modules_of_category")}</h1>
            <Collapse accordion>
                {
                    modules.map((module: any) => {
                        return <Collapse.Panel header={module.title_lv} key={module.id}>
                            <h3><b>{module.description_lv}</b></h3>
                            {
                                module.chapters.map((chapter: any, index: number) => {
                                    return <ChapterTitleCard
                                        key={index}
                                        title={chapter.title_lv}
                                        module={module}
                                        ind={index}
                                        moduleID={module.id}
                                        chapterID={chapter.id}
                                        categoryID={catid}
                                    />
                                })
                            }
                        </Collapse.Panel>
                    })
                }
            </Collapse>
        </div>
    </div>
}